.history-data-box {
  text-align: left;
}
.history-data-box p {
  margin: 0;
}
.history-data-box .marginBottom {
  margin-bottom: 20px;
}
.history-data-box .history-data-date {
  margin-bottom: 30px;
}
.history-data-box .history-data-date .el-range-editor.el-input__inner {
  border-radius: 25px;
}
.history-data-box .history-data-date /deep/ .el-date-editor .el-range__icon {
  margin-left: 3px;
  line-height: 20px;
}
.history-data-box .history-data-date /deep/ .el-date-editor .el-range__close-icon {
  line-height: 20px;
}
.history-data-box .history-data-date /deep/ .el-date-editor .el-range-separator {
  line-height: 20px;
}
.history-data-box .param-box {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.history-data-box .param-box .arrow {
  width: 14px;
  height: 26px;
  padding: 0px 0px;
}
.history-data-box .param-box .arrow i {
  font-style: normal;
}
.history-data-box .param-box .param-list {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  overflow-x: hidden;
}
.history-data-box .param-box .param-list .param-row {
  margin: 0px 5px;
  padding: 0px 5px;
  min-width: 100px;
  height: 26px;
  background: #f2f2f2;
  border-radius: 2px 2px 0px 0px;
  font-size: 14px;
  text-align: center;
  line-height: 26px;
  color: #666666;
  cursor: pointer;
}
.history-data-box .param-box .param-list .param-row:hover {
  background: #0079c1;
  color: #ffffff;
}
.history-data-box .param-box .param-list .active {
  background: #0079c1;
  color: #ffffff;
}
.history-data-box .history-latest-box {
  width: 100%;
  height: 300px;
}
.history-data-box .effic-rate-box {
  width: 100%;
  height: 300px;
}
.history-data-box .history-fault-box {
  height: 300px;
}
.history-data-box .history-fault-box .history-fault-pie-box {
  width: 30%;
  height: 100%;
  float: left;
}
.history-data-box .history-fault-box .history-fault-bar-box {
  width: 70%;
  height: 100%;
  float: right;
}
.history-data-box .history-map-box {
  width: 100%;
  height: 563px;
}
