.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 4vw;
  padding: 30% 0 20% 0;
}
.emptyFile .el-upload {
  border: 1px dashed #ff0000 !important;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100%;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 24%;
  height: 100%;
  display: inline-block;
}
/*OSversionTabs组件标题*/
.OSVersion-menu {
  width: 57%;
  height: 252px;
  background: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 3px;
}
/*OSversionTabs组件内容*/
.OSVersion-content {
  height: 160px;
  width: 97%;
  padding-left: 10px;
  padding-top: 7px;
  text-align: left;
}
.OSVersion-content .el-tabs__item {
  padding: 0 5px !important;
}
.OSVersion-content-title {
  padding: 0 10px !important;
  font-size: 16px !important;
  font-weight: 400;
}
.is-active .OSVersion-content-title {
  font-weight: 700 !important;
}
.SplitLine {
  width: 100%;
  height: 1px;
  background: #eeeeee;
  float: bottom;
}
.AddOS-Button {
  color: #000000 !important;
  border-radius: 3px;
  float: left;
  margin-top: 20px!important;
  margin-left: 24px!important;
  margin-bottom: 18px !important;
  border: 1px solid #eeeeee !important;
}
.AddOS-Button:hover {
  border: 1px solid #0079c1 !important;
  color: #0079c1 !important;
  background-color: transparent !important;
}
.formCard .upload {
  width: 100%;
  font-family: Arial, Arial-Regular;
  font-weight: 400;
  text-align: center;
  color: #555555;
}
.Add {
  width: 100%;
  line-height: 20px;
  font-size: 13px;
  font-family: Arial, Arial-Regular;
  font-weight: 400;
  text-align: center;
  color: #555555;
  margin-top: 5% ;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-right: 10px;
}
.BottomButton {
  float: left;
  margin-top: 20px;
  margin-left: 335px;
}
/*Clear按钮样式*/
.ClearButton {
  width: 110px;
  height: 38px;
  border: 1px solid #0079c1;
  border: 1px solid #cbcbcd;
  border-radius: 3px;
  margin-right: 20px;
}
/*顶部边距50px*/
.marginTop50 {
  margin-top: 50px;
}
/*顶部边距35px*/
.marginTop35 {
  margin-top: 50px;
}
/*顶部边距30px*/
.marginTop30 {
  margin-top: 30px;
}
/*顶部边距60px*/
.marginTop60 {
  margin-top: 60px;
}
/*顶部边距-20px*/
.marginTop-20 {
  margin-top: -20px;
}
.GenerateButton:hover {
  background: #0369A6 !important;
}
/*隐藏上传文件列表*/
.el-upload-list {
  display: none;
}
/*调整表单label右边距*/
.el-form-item__label {
  padding-right: 19px !important;
}
/*备注组件样式*/
.formCard .remark textarea,
.newOSRemark textarea {
  font-size: 14px;
  font-family: Arial, Arial-Regular;
  padding: 30px;
  height: 116px;
}
/*Generate、Clear、Save、Cancel按钮大小*/
.defaultButton {
  width: 110px;
  height: 38px;
}
/*Generate按钮样式*/
.primaryButton {
  margin-left: 20px !important;
}
/*OSVersion Tabs组件中按钮大小*/
.OSVersion-content button {
  width: 61px !important;
  height: 36px !important;
  padding: 0px !important;
}
/*按钮左对齐*/
.buttonLeft {
  text-align: left;
  margin-top: 30px;
  display: inline-block;
}
/*上传组件中有文件时图片位置*/
.el-icon-folder-opened {
  padding: 2.5px 0;
}
/*上传组件中有文件时文件名位置*/
.haveFile {
  padding-top: 3px;
}
/*Device页面上传组件总宽度*/
.width760 {
  width: 58%;
  margin-top: -20px;
  line-height: 0px;
}
/*上传组件样式*/
.uploadFile {
  display: inline-block;
  width: 23%;
  min-width: 80px;
  margin-right: 2%;
}
/*Device页面备注框样式*/
.formCard .remark {
  width: 57% !important;
}
/*Device页面表单项样式*/
.formItem {
  width: 100%;
  text-align: left;
}
/*DeviceName样式*/
.DeviceName {
  width: 33% !important;
}
.DeviceName:hover,
.OSVersion:hover {
  box-shadow: 0px 0px 8px 0px rgba(51, 153, 255, 0.8);
  border: 1px solid #3399FF !important;
  border-radius: 3px;
}
.DeviceName .el-input__inner {
  padding-left: 30px !important;
}
/*New OS弹窗页面OSVersion样式*/
.OSVersion {
  width: 428px !important;
}
.OSVersion .el-input__inner {
  padding-left: 30px !important;
}
/*dialog关闭按钮位置*/
.el-dialog__headerbtn {
  top: 23px !important;
  /*right: 40px  !important;*/
}
/*dialog标题样式*/
.el-dialog__header {
  padding: 23px 40px 23px 41px !important;
  background-color: #f2f2f2;
  height: 14px;
}
.el-tabs__nav-wrap::after {
  background-color: #eeeeee !important;
  height: 1px !important;
}
button {
  border-radius: 3px !important;
}
.tabButton:hover {
  background-color: transparent !important;
}
.formCard {
  margin-top: 16px;
}
.newOSRemark {
  width: 760px !important;
}
.newOS-upload .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 178px;
  height: 178px;
}
.newOS-upload .el-upload:hover {
  border-color: #409EFF;
}
.newOS-upload-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.newOSAdd {
  width: 100%;
  line-height: 20px;
  font-size: 13px;
  font-family: Arial, Arial-Regular;
  font-weight: 400;
  text-align: center;
  color: #555555;
  margin-top: 23px ;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-right: 10px;
}
.newOSupload {
  width: 100%;
  height: 63px;
  font-size: 48px;
  font-family: Arial, Arial-Regular;
  font-weight: 400;
  text-align: center;
  color: #555555;
  margin-top: 58px;
}
/*上传组件中有文件时文件名位置*/
.newOSHaveFile {
  margin-top: 10px !important;
}
/*上传组件中有文件时图片位置*/
.newOSupload .el-icon-folder-opened {
  vertical-align: top !important;
  margin-top: 5px;
  padding: 0px !important;
}
