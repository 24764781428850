












































































































































































































































































































































































































































































































































































































































































































.machine-upgrade-box {
    position: relative;
    text-align: left;
    font-size: 14px;

    .machine-upgrade-header {
        font-weight: bold;
        margin-bottom: 20px;
        
    }
    .failed{
        color:#F34863 ;
    }
    .machine-upgrade-body {
        border: 1px solid #ccc;
        border-radius: 2px;
        .machine-upgrade-body-content {
            padding: 0 20px;

            .machine-body-header {
                height: 60px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-bottom: 1px solid rgba(0, 0, 0, 0.1);

                .machine-body-header-btn {
                    background: #00b194;
                    border-radius: 2px;
                    width: 160px;
                    height: 32px;
                    display: inline-block;
                    color: #fff;
                    line-height: 32px;
                    text-align: center;
                    cursor: pointer;

                    &:hover {
                        opacity: 0.8;
                    }
                }
            }

            .machine-body-content {
                height: 400px;
                padding: 20px 0;
                overflow-y: auto;
                /deep/.el-radio {
                    display: block;
                    margin: 10px auto;
                }
            }
        }
        .machine-upgrade-body-footer {
            height: 78px;
            background: #ffffff;
            border-top: 1px solid #cccccc;
            display: flex;
            justify-content: center;
            align-items: center;

            button {
                // display: inline-block;
                width: 110px;
                height: 38px;
                // background: #ffffff;
                // border: 1px solid #cbcbcd;
                // border-radius: 4px;
                // cursor: pointer;
                // text-align: center;
                // line-height: 38px;

                // &:last-child {
                //     height: 38px;
                //     background: #047bc2;
                //     border-radius: 4px;
                //     color: #fff;
                //     margin-left: 20px;
                // }

                // &:hover {
                //     opacity: 0.8;
                // }
            }
        }
    }

    .add-soft-box {
        /deep/ .el-dialog__body {
            padding: 30px 85px;
        }

        /deep/ .el-dialog__footer {
            padding: 0;
        }
        .uploading{
            height: 39px;
        }
        .upload {
            display: inline-block;
            top: 0;
            position: absolute;
            left: 35px;
            padding-top: 92px;
            color: #555555;
            width: 110px;
        }

        .add-soft-footer {
            text-align: center;
            border-top: 1px solid rgba(204, 204, 204, 0.4);
            padding: 20px 0;
        }
    }

    .upload-text-box {
        text-align: center;
        height: 30px;
        background: #f2f2f2;
        border-radius: 3px;
        line-height: 30px;
        color: #666666;
        width: 177px;
        border-top-right-radius: 0;
        border-top-left-radius: 0;
        position: absolute;
        bottom: 1px;
        left: 1px;
        border-top: 1px dashed #c0ccda;
    }

    /deep/.el-upload--picture-card {
        position: relative;
        width: 180px;
        height: 180px;
        border: 1px solid #ccc;
        background-color: #fff;
        padding: 27% 0;
        line-height: inherit;
        i{
        font-weight: 1000;
        color: #555555;
        }
        .haveFile{
        white-space: nowrap;
        text-overflow:ellipsis;
        overflow: hidden;
        padding: 0 10%;
        }
    }

    .hide /deep/.el-upload {
        display: none;
    }
}
.updgrade-device-box {
    position: relative;
    text-align: left;

    p {
        margin: 0;
    }
    .marginBottom {
        margin-bottom: 20px;
    }

    .updgrade-basic-list-box {
        margin-bottom: 30px;
    }
    .updgrade-basic-box {
        .updgrade-basic-list {
            margin-bottom: 9px;
            height: 68px;
            border: 1px solid #cccccc;
            border-radius: 0px 2px 2px 0px;

            .updgrade-basic-list-icon {
                width: 146px;
                box-sizing: border-box;
                height: 100%;
                background: #f1f9fe;
                border-right: 1px solid #cccccc;
                display: flex;
                justify-content: center;
                align-items: center;
                float: left;

                img {
                    width: 59px;
                    height: 60px;
                }
            }

            .updgrade-basic-list-content {
                padding-left: 47px;
                float: left;
                font-size: 14px;
                color: #333;
                margin-top: 15px;
                .updgrade-basic-list-title {
                    color: #000;
                    font-weight: bold;
                    margin-bottom: 5px;
                }
                .updgrade-basic-list-desc {
                    p {
                        float: left;
                        margin-right: 40px;
                    }
                }
            }
        }
    }
}
.card-header-title {
            font-size: 16px;
            color: #333333;
            padding-left: 30px;
            font-weight: bold;
            background: url('../../assets/image/icon.png') left center no-repeat;
        }
