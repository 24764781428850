.rdmCard .el-card__body {
  padding: 0px !important;
}
.rdmCard {
  border: 0px !important;
}
/*面包屑样式 */
.breadcrumbView {
  height: 30px;
  border-bottom: 1px solid #eaeef0;
  padding: 20px;
  margin-bottom: 20px;
  background-color: #fafafa;
}
.breadcrumbView .el-breadcrumb {
  vertical-align: middle;
  display: inline-block;
  width: 100%;
}
/*RDM信息样式 */
.rdmInfo-content {
  padding: 0 12% 1% 12%;
  margin-top: 3%;
  /*底部按钮样式 */
  /*隐藏数字框右边按钮 */
  /*弹窗底部按钮样式 */
}
.rdmInfo-content .el-select {
  display: block;
}
.rdmInfo-content .el-form-item__label {
  padding-right: 20px;
}
.rdmInfo-content .footer {
  text-align: left;
}
.rdmInfo-content .footer .el-button {
  width: 110px;
  height: 38px;
}
.rdmInfo-content .footer .el-button--default:hover {
  background: transparent !important;
}
.rdmInfo-content .footer .el-button--primary {
  margin-left: 20px;
}
.rdmInfo-content input::-webkit-outer-spin-button,
.rdmInfo-content input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
.rdmInfo-content input[type="number"] {
  -moz-appearance: textfield;
}
.rdmInfo-content .tip {
  padding: 0px 14px 13px 14px;
  width: 100%;
  text-align: left;
  color: #fd990d;
  margin-top: -10px;
}
.rdmInfo-content .footerButton .el-button {
  width: 110px;
  height: 38px;
  background: #ffffff;
  border: 1px solid #cbcbcd;
  border-radius: 4px !important;
}
.rdmInfo-content .footerButton .el-button--primary {
  margin-left: 20px;
}
/*下拉框选项样式 */
.el-popper .el-select-dropdown__item:hover {
  background-color: #fafafa;
}
.el-popper .selected {
  font-weight: 400 !important;
  color: #3399ff !important;
  background-color: #ecf5ff;
}
.el-popper .hover {
  background-color: #ecf5ff;
}
input {
  border-radius: 3px;
}
/*输入框鼠标悬停添加阴影 */
input:hover,
textarea:hover,
.is-focus input {
  box-shadow: 0px 0px 8px 0px rgba(51, 153, 255, 0.8);
  border: 1px solid #3399ff !important;
  border-radius: 3px;
}
.el-input.is-disabled .el-input__inner {
  border-color: #ccc !important;
}
.software-table {
  margin-top: 21px;
}
.software-table .table-box {
  border: 1px solid #F2F2F2;
}
.software-table .table-box /deep/ .el-table__header tr th {
  background: #FAFAFA;
}
.software-table .table-box .rdmon {
  cursor: pointer;
  width: 19px;
  height: 19px;
  margin: 0 auto;
  background: url('../../assets/image/rdmon.png');
}
.software-table .table-box .point {
  cursor: pointer;
}
.software-table .table-box .wifi-off {
  width: 21px;
  height: 18px;
  margin: 0 auto;
  background: url('../../assets/image/wifi_off.png');
}
.software-table .table-box .gray {
  font-size: 21px;
  color: #999999;
}
.software-table .table-box .green {
  font-size: 21px;
  color: #00b194;
}
.software-table .table-box .red {
  font-size: 21px;
  color: #fe686a;
}
.software-table .pagination {
  margin-top: 20px;
  text-align: right;
  padding-bottom: 40px;
}
.software-table .pagination .paginationCount {
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  color: #666666;
}
.software-table .pagination .paginationDiv {
  display: inline-block;
}
