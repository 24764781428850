
























































































































































































































































































































































































































































































































































































































































































































































































































.el-tree {
    height: 486px;
    overflow: auto;
}
.parameter-data-box {
    position: relative;
    text-align: left;

    p {
        margin: 0;
    }
    .marginBottom {
        margin-bottom: 20px;
    }

    .parameter-basic-list-box {
        margin-bottom: 30px;
    }
    .parameter-basic-box {
        .parameter-basic-list {
            margin-bottom: 9px;
            height: 68px;
            border: 1px solid #cccccc;
            border-radius: 0px 2px 2px 0px;
            
            &:hover,
            &.isSelected {
                border-color: #3399FF;
                box-shadow: 0px 0px 6px 0px #3399FF;
                background-color: #F1F9FE;
            }

            .parameter-basic-list-icon {
                width: 146px;
                box-sizing: border-box;
                height: 100%;
                background: #f1f9fe;
                border-right: 1px solid #cccccc;
                display: flex;
                justify-content: center;
                align-items: center;
                float: left;

                img {
                    width: 59px;
                    height: 60px;
                }
            }

            .parameter-basic-list-content {
                padding-left: 47px;
                float: left;
                font-size: 14px;
                color: #333;
                margin-top: 15px;
                cursor:pointer;
                .parameter-basic-list-title {
                    color: #000;
                    font-weight: bold;
                    margin-bottom: 5px;
                }
                .parameter-basic-list-desc {
                    p {
                        float: left;
                        margin-right: 40px;
                    }
                }
            }
        }
    }

    .parameter-data-list-box {
        &::after {
            content: "";
            display: block;
            overflow: hidden;
            width: 0;
            height: 0;
            clear: both;
        }
        .parameter-data-list-left {
            width: 30%;
            height: 600px;
            border: 1px solid #f2f2f2;
            float: left;

            .parameter-data-list-left-title {
                height: 48px;
                line-height: 48px;
                padding-left: 14px;
                background: #fafafa;
                border-bottom: 1px solid #f2f2f2;
            }

            .parameter-data-list-left-input {
                margin: 12px;
            }
        }

        .parameter-data-list-right {
            width: 68%;
            height: 600px;
            border: 1px solid #f2f2f2;
            float: right;

            /deep/.el-table th {
                background: #fafafa;
                border-bottom: 1px solid #f2f2f2;
            }
            /deep/.el-table input {
                width: 59px;
            }
        }
    }

    .parameter-data-list-footer {
        margin-top: 20px;
        height: 78px;
        background: #ffffff;
        border-top: 1px solid #cccccc;
        display: flex;
        justify-content: center;
        align-items: center;

        span {
            display: inline-block;
            width: 110px;
            height: 38px;
            background: #ffffff;
            border: 1px solid #cbcbcd;
            border-radius: 4px;
            cursor: pointer;
            text-align: center;
            line-height: 38px;

            &:last-child {
                width: 250px;
                height: 38px;
                background: #047bc2;
                border-radius: 4px;
                color: #fff;
                margin-left: 20px;
            }

            &:hover {
                opacity: 0.8;
            }
        }
    }
}
