/*重新选择图片上传组件样式 */
.Rebulid {
  width: 100%;
  padding: 0px 14px 13px 14px;
}
.Rebulid .el-upload {
  text-align: left;
  width: 100%;
}
.Rebulid .el-button {
  height: 34px;
  background: #ffffff;
  border: 1px solid #eaeef0;
  border-radius: 2px !important;
  margin-right: 11px;
  color: #0079c1;
}
.Rebulid .fileName {
  display: inline-block;
  color: #7a7a7a;
}
/*裁剪框样式 */
.cropper-content {
  /*预览区域样式 */
}
.cropper-content .cropper-box {
  display: inline-block;
  width: 290px;
}
.cropper-content .cropper-box .cropper {
  width: auto;
  height: 294px;
}
.cropper-content .cropper-box .cropper .cropper-box {
  background: #898989;
}
.cropper-content .show-preview {
  height: 292px;
  width: 150px;
  text-align: center;
  border: 1px solid #eaeef0;
  display: inline-block;
  vertical-align: top;
}
.cropper-content .show-preview .previewsTitle {
  margin-top: 15px;
  color: #696969;
}
.cropper-content .show-preview .preview {
  overflow: hidden;
  width: 100px !important;
  height: 100px !important;
  border-radius: 50px;
  border: 1px solid #0079c1;
  margin: 45px auto;
  display: inline-block;
}
.tip {
  padding: 0px 14px 13px 14px;
  width: 100%;
  text-align: left;
  color: #fd990d;
}
/*弹窗底部按钮样式 */
.footerButton {
  text-align: center;
}
.el-dialog-custom {
  text-align: left;
  font-size: 14px;
  /*上传组件样式 */
}
.el-dialog-custom /deep/ .el-dialog__title {
  font-weight: 700;
  font-size: 16px;
}
.el-dialog-custom .el-input {
  width: 300px;
}
.el-dialog-custom .el-textarea {
  width: 555px;
}
.el-dialog-custom /deep/ .el-upload--picture-card {
  width: 102px;
  height: 102px;
  line-height: 102px;
}
.el-dialog-custom /deep/ .el-upload-list__item {
  width: 102px !important;
  height: 102px !important;
}
.el-dialog-custom /deep/ .upload {
  display: inline-block;
  top: 0;
  position: absolute;
  left: 17px;
  padding-top: 20px;
  color: #555555;
  width: 70px;
}
.el-dialog-custom /deep/ .el-icon-plus {
  color: #555555;
  vertical-align: top;
  margin-top: 20px;
  font-size: 20px;
  line-height: 40px;
}
.el-dialog-custom /deep/ .hide .el-upload {
  display: none;
}
.el-dialog-custom button {
  width: 110px;
  height: 38px;
}
.el-dialog-custom /deep/ .el-dialog__footer {
  text-align: center;
}
.company-detail-box .row-label-box {
  font-size: 16px;
  color: #333;
  font-weight: bold;
}
.company-detail-box .row1 {
  box-sizing: border-box;
  border-radius: 2px;
}
.company-detail-box .row1 .row1-header-box {
  height: 70px;
  line-height: 70px;
  color: #000;
  background: #fafafa;
  padding: 0 2%;
}
.company-detail-box .row1 .row1-header-box .row1-header-crumbs {
  font-size: 16px;
  font-weight: bold;
  float: left;
  height: 70px;
}
.company-detail-box .row1 .row1-header-box .row1-header-crumbs .el-breadcrumb {
  vertical-align: middle;
  display: inline-block;
}
.company-detail-box .row1 .row1-header-box .row1-header-btn {
  color: #fff;
  font-size: 14px;
  float: right;
}
.company-detail-box .row1 .row1-header-box .row1-header-btn span {
  display: inline-block;
  margin-left: 10px;
  min-width: 120px;
  background: #0079C1;
  height: 38px;
  line-height: 38px;
  cursor: pointer;
  border-radius: 2px;
}
.company-detail-box .row1 .row1-header-box .row1-header-btn span:last-child {
  background: #FF9933;
}
.company-detail-box .row1 .row1-header-box .row1-header-btn span:hover {
  opacity: 0.8;
}
.company-detail-box .row1 .row1-body-box {
  height: 142px;
  display: flex;
  justify-content: space-between;
  padding: 0 42px;
  background: #fff;
  border-radius: 2px;
  border-top: rgba(0, 0, 0, 0.1) solid 1px;
}
.company-detail-box .row1 .row1-body-box .online-state {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}
.company-detail-box .row1 .row1-body-box .online-state .carMain {
  display: inline-block;
}
.company-detail-box .row1 .row1-body-box .online-state .carMain .carMainTop {
  font-size: 30px;
  font-weight: 700;
  display: flex;
  flex-wrap: nowrap;
}
.company-detail-box .row1 .row1-body-box .online-state .carMain .carMainTop .carOnlineCount {
  width: 90px;
  text-align: center;
  color: #0079c1;
}
.company-detail-box .row1 .row1-body-box .online-state .carMain .carMainTop .carOfflineCount {
  width: 90px;
  text-align: center;
  color: #f34863;
}
.company-detail-box .row1 .row1-body-box .online-state .carMain .carMainTop .onlineCount {
  width: 90px;
  text-align: center;
  color: #00B194;
}
.company-detail-box .row1 .row1-body-box .online-state .carMain .carMainTop .offlineCount {
  width: 90px;
  text-align: center;
  color: #909399;
}
.company-detail-box .row1 .row1-body-box .online-state .carMain .carMainTop .carCount {
  width: 90px;
  text-align: center;
  color: #04223a;
}
.company-detail-box .row1 .row1-body-box .online-state .carMain .carMainBottom {
  padding-top: 10px;
  height: 19px;
}
.company-detail-box .row1 .row1-body-box .online-state .carMain .carMainBottom .imgLogo {
  height: 19px;
  display: inline-block;
}
.company-detail-box .row1 .row1-body-box .online-state .carMain .carMainBottom .carMainText {
  height: 19px;
  font-size: 14px;
  line-height: 19px;
  font-weight: 400;
  text-align: center;
  color: #666666;
  margin-left: 6px;
  display: inline-block;
}
.company-detail-box .row1 .row1-body-box .online-state .carChart {
  width: 145px;
  height: 91px;
}
.company-detail-box .row1 .row1-body-box .row1-body-border {
  border-left: #ffffff solid 1px;
  border-right: #ffffff solid 1px;
  border-color: rgba(0, 0, 0, 0.1);
}
.company-detail-box .row2 {
  background: #fff;
  padding: 0 42px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0px;
  height: 108px;
}
.company-detail-box .row2 /deep/ .el-cascader {
  flex: 1;
  margin-left: 12px;
}
.company-detail-box .row3 {
  background: #fff;
  border-radius: 2px;
}
.company-detail-box .row3 .row3-header-label {
  height: 80px;
  line-height: 80px;
  text-align: left;
  font-size: 16px;
  font-weight: bold;
  background: #FAFAFA;
  padding: 0 42px;
}
.company-detail-box .row3 .row3-body-box {
  padding: 0 42px;
}
.company-detail-box .row3 .row3-body-box .row3-body-search {
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
}
.company-detail-box .row3 .row3-body-box .row3-body-search .row3-body-search-btn span {
  display: inline-block;
  margin-left: 25px;
  cursor: pointer;
}
.company-detail-box .row3 .row3-body-box .row3-body-search .row3-body-search-btn span i {
  margin-right: 10px;
}
.company-detail-box .row3 .row3-body-box .row3-body-search .row3-body-search-btn span:hover {
  color: #0079C1;
  text-decoration: underline;
}
.company-detail-box .row3 .row3-body-box .row3-body-search .row3-body-search-btn span:last-child {
  background: #0079C1;
  color: #fff;
  border-radius: 2px;
  padding: 10px 20px;
}
.company-detail-box .row3 .row3-body-box .row3-body-table .row3-body-table-box {
  border: 1px solid #F2F2F2;
}
.company-detail-box .row3 .row3-body-box .row3-body-table .row3-body-table-box /deep/ .el-table__header tr th {
  background: #FAFAFA;
}
.company-detail-box .row3 .row3-body-box .row3-body-table .row3-body-table-box .row3-table-operation {
  display: flex;
  justify-content: space-around;
  color: #0079C1;
}
.company-detail-box .row3 .row3-body-box .row3-body-table .row3-body-table-box .row3-table-operation i {
  cursor: pointer;
}
.company-detail-box .row3 .row3-body-box .row3-body-table .row3-body-table-box .row3-table-operation i:hover {
  opacity: 0.8;
}
.company-detail-box .row3 .row3-body-box .row3-body-table .row3-body-table-box .row3-table-operation i:last-child {
  color: #FF4D4F;
}
.company-detail-box .row3 .row3-body-box .row3-body-table .pagination {
  margin-top: 20px;
  text-align: right;
  padding-bottom: 40px;
}
.company-detail-box .row3 .row3-body-box .row3-body-table .pagination .paginationCount {
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  color: #666666;
}
.company-detail-box .row3 .row3-body-box .row3-body-table .pagination .paginationDiv {
  display: inline-block;
}
.qrcode {
  font-size: 14px;
  font-weight: 400;
  color: #0079c1;
  cursor: pointer;
}
.ksiComment {
  font-size: 12px;
  color: #909399;
}
