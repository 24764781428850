







































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.history-data-box {
    text-align: left;
    p {
        margin: 0;
    }
    .marginBottom {
        margin-bottom: 20px;
    }
    .history-data-date {
        margin-bottom: 30px;

        .el-range-editor.el-input__inner {
            border-radius: 25px;
        }
        /deep/ .el-date-editor .el-range__icon {
            margin-left: 3px;
            line-height: 20px;
        }
        /deep/ .el-date-editor .el-range__close-icon {
            line-height: 20px;
        }
        /deep/ .el-date-editor .el-range-separator {
            line-height: 20px;
        }
    }
    .param-box{
        width: 100%;
        display: flex;
        justify-content:space-between;
        .arrow{
            width: 14px;
            height: 26px;
            padding: 0px 0px;
            i{
                font-style: normal;
            }
        }
        .param-list{
            width: 100%;
            display: flex;
            justify-content: flex-start ;
            overflow-x: hidden;
            .param-row{
                margin: 0px 5px;
                padding: 0px 5px;
                min-width: 100px;
                height: 26px;
                background: #f2f2f2;
                border-radius: 2px 2px 0px 0px;
                font-size: 14px;
                text-align: center;
                line-height: 26px;
                color: #666666;
                cursor: pointer;
                &:hover{
                    background: #0079c1;
                    color: #ffffff;
                }
            }
            .active{
                background: #0079c1;
                color: #ffffff;
            }
        }
        
    }
    .history-latest-box {
        width: 100%;
        height: 300px;
    }

     .effic-rate-box {
        width: 100%;
        height: 300px;
    }

    .history-fault-box {
        height: 300px;

        .history-fault-pie-box {
            width: 30%;
            height: 100%;
            float: left;
        }

        .history-fault-bar-box {
            width: 70%;
            height: 100%;
            float: right;
        }
    }

    .history-map-box {
        width: 100%;
        height: 563px;
    }
}
