.el-dialog-custom {
  font-size: 14px;
  /*上传组件样式*/
  /*上传组件中有文件时图片位置*/
  /*上传组件中有文件时文件名位置*/
}
.el-dialog-custom /deep/ .el-dialog__header {
  text-align: left;
}
.el-dialog-custom /deep/ .el-dialog__body {
  text-align: center;
}
.el-dialog-custom /deep/ .el-dialog__title {
  font-weight: 700;
  font-size: 16px;
}
.el-dialog-custom button {
  width: 110px;
  height: 38px;
}
.el-dialog-custom .el-button + .el-button {
  margin-left: 20px;
}
.el-dialog-custom .el-button + .el-button + .el-checkbox {
  margin-left: 20px;
}
.el-dialog-custom .uploadFile {
  display: inline-block;
  width: 30%;
  margin-right: 2%;
}
.el-dialog-custom .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 4vw;
  padding: 30% 0 20% 0;
}
.el-dialog-custom .emptyFile .el-upload {
  border: 1px dashed #ff0000 !important;
}
.el-dialog-custom .avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.el-dialog-custom .avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100%;
  height: 178px;
  text-align: center;
}
.el-dialog-custom .avatar {
  width: 24%;
  height: 100%;
  display: inline-block;
}
.el-dialog-custom .Add {
  width: 100%;
  line-height: 20px;
  font-size: 13px;
  font-family: Arial, Arial-Regular;
  font-weight: 400;
  text-align: center;
  color: #555555;
  margin-top: 15% ;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-right: 10px;
}
.el-dialog-custom .el-icon-folder-opened {
  padding: 2.5px 0;
}
.el-dialog-custom .haveFile {
  padding-top: 3px;
}
.el-dialog-custom .upload {
  width: 100%;
  font-family: Arial, Arial-Regular;
  font-weight: 400;
  text-align: center;
  color: #555555;
}
.el-table .default {
  height: 21px;
}
.soft-box {
  margin-top: 10px;
  background: #ffffff;
  border-radius: 2px;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1);
}
.soft-box .soft-top {
  border-bottom: 1px solid #e5e5e5;
  background: #fafafa;
  border-radius: 2px;
  padding: 0 18px;
  height: 79px;
}
.soft-box .soft-top .title {
  font-size: 16px;
  font-weight: 700;
  line-height: 79px;
  float: left;
  margin-left: 26px ;
}
.soft-box .soft-top .search {
  height: 48px;
  line-height: 79px;
}
.soft-box .soft-content {
  padding: 20px;
}
.soft-box .soft-content .soft-content-top {
  float: left;
}
.soft-box .soft-content .soft-table {
  margin-top: 21px;
}
.soft-box .soft-content .soft-table .table-box {
  border: 1px solid #F2F2F2;
}
.soft-box .soft-content .soft-table .table-box /deep/ .el-table__header tr th {
  background: #FAFAFA;
}
.soft-box .soft-content .soft-table .table-box .green {
  color: #00b194;
}
.soft-box .soft-content .soft-table .table-box .table-operation {
  display: flex;
  justify-content: space-around;
  color: #0079C1;
}
.soft-box .soft-content .soft-table .table-box .table-operation i {
  cursor: pointer;
}
.soft-box .soft-content .soft-table .table-box .table-operation i:hover {
  opacity: 0.8;
}
.soft-box .soft-content .soft-table .table-box .table-operation i:last-child {
  color: #FF4D4F;
}
.soft-box .soft-content .soft-table .pagination {
  margin-top: 20px;
  text-align: right;
  padding-bottom: 40px;
}
.soft-box .soft-content .soft-table .pagination .paginationCount {
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  color: #666666;
}
.soft-box .soft-content .soft-table .pagination .paginationDiv {
  display: inline-block;
}
