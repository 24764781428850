










































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































/*重新选择图片上传组件样式 */
  .Rebulid {
    width: 100%;
    padding: 0px 14px 13px 14px;
    .el-upload {
      text-align: left;
      width: 100%;
    }
    .el-button {
      height: 34px;
      background: #ffffff;
      border: 1px solid #eaeef0;
      border-radius: 2px !important;
      margin-right: 11px;
      color: #0079c1;
    }
    .fileName {
      display: inline-block;
      color: #7a7a7a;
    }
  }
  /*裁剪框样式 */
  .cropper-content {
    .cropper-box {
      display: inline-block;
      width: 290px;
      .cropper {
        width: auto;
        height: 294px;
        .cropper-box {
          background: #898989;
        }
      }
    }
    /*预览区域样式 */
    .show-preview {
      height: 292px;
      width: 150px;
      text-align: center;
      border: 1px solid #eaeef0;
      display: inline-block;
      vertical-align: top;
      .previewsTitle {
        margin-top: 15px;
        color: #696969;
      }
      .preview {
        overflow: hidden;
        width: 100px !important;
        height: 100px !important;
        border-radius: 50px;
        border: 1px solid #0079c1;
        margin: 45px auto;
        display: inline-block;
      }
    }
  }
  .tip {
    padding: 0px 14px 13px 14px;
    width: 100%;
    text-align: left;
    color: #fd990d;
  }

  /*弹窗底部按钮样式 */
  .footerButton {
    text-align: center;
  }
.el-dialog-custom {
    text-align: left;
    font-size: 14px;
    /deep/ .el-dialog__title{
        font-weight: 700;
        font-size: 16px;
    }
    .el-input {
        width: 300px;
    }
    .el-textarea{
        width: 555px;
    }
    /*上传组件样式 */
    /deep/ .el-upload--picture-card {
        width: 102px;
        height: 102px;
        line-height: 102px;
    }
    /deep/ .el-upload-list__item {
        width: 102px !important;
        height: 102px !important;
    }
    /deep/ .upload {
        display: inline-block;
        top: 0;
        position: absolute;
        left: 17px;
        padding-top: 20px;
        color: #555555;
        width: 70px;
    }
    /deep/ .el-icon-plus {
        color: #555555;
        vertical-align: top;
        margin-top: 20px;
        font-size: 20px;
        line-height: 40px;
    }
    /deep/ .hide .el-upload {
        display: none;
    }
    button {
        width: 110px;
        height: 38px;
    }
    /deep/ .el-dialog__footer{
        text-align: center;
    }
}
.company-detail-box {

    .row-label-box {
        font-size: 16px;
        color: #333;
        font-weight: bold;
    }

    .row1 {
        box-sizing: border-box;
        border-radius: 2px;
        .row1-header-box {
            height: 70px;
            line-height: 70px;
            color: #000;
            background: #fafafa;
            // display: flex;
            // justify-content: space-between;
            // align-items: center;
            padding: 0 2%;

            .row1-header-crumbs {
                font-size: 16px;
                font-weight: bold;
                float: left;
                height: 70px;
                .el-breadcrumb {
                    vertical-align: middle;
                    display: inline-block;
                }
            }

            .row1-header-btn {
                color: #fff;
                font-size: 14px;
                float: right;

                span {
                    display: inline-block;
                    margin-left: 10px;
                    min-width: 120px;
                    background: #0079C1;
                    height: 38px;
                    line-height: 38px;
                    cursor: pointer;
                    border-radius: 2px;

                    &:last-child {
                        background: #FF9933;
                    }

                    &:hover {
                        opacity: 0.8;
                    }
                }
            }
        }

        .row1-body-box {
            height: 142px;
            display: flex;
            justify-content: space-between;
            padding: 0 42px;
            background: #fff;
            border-radius: 2px;
            border-top: rgba(0, 0, 0, 0.1) solid 1px;
            .online-state {
                display: flex;
                flex-grow: 1;
                flex-direction:column;
                align-items: center;
                justify-content: space-around;
                .carMain {
                    display: inline-block;
                    .carMainTop {
                        font-size: 30px;
                        font-weight: 700;
                        display: flex;
                        flex-wrap: nowrap;

                        .carOnlineCount {
                            width: 90px;
                            text-align: center;
                            color: #0079c1;
                        }
                        .carOfflineCount {
                            width: 90px;
                            text-align: center;
                            color: #f34863;
                        }
                        .onlineCount {
                            width: 90px;
                            text-align: center;
                            color: #00B194;
                        }
                        .offlineCount {
                            width: 90px;
                            text-align: center;
                            color: #909399;
                        }
                        .carCount {
                            width: 90px;
                            text-align: center;
                            color: #04223a;
                        }
                    }
                    .carMainBottom {
                        padding-top: 10px;
                        height: 19px;
                        .imgLogo {
                            height: 19px;
                            display: inline-block;
                        }
                        .carMainText {
                            height: 19px;
                            font-size: 14px;
                            line-height: 19px;
                            font-weight: 400;
                            text-align: center;
                            color: #666666;
                            margin-left: 6px;
                            display: inline-block;
                        }
                    }
                }
                .carChart {
                    width: 145px;
                    height: 91px;
                }                
            }

            .row1-body-border {
                border-left: #ffffff solid 1px;
                border-right: #ffffff solid 1px;
                border-color: rgba(0, 0, 0, 0.1);
            }
        }
    }

    .row2 {
        background: #fff;
        padding: 0 42px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 10px 0px;
        height: 108px;

        /deep/ .el-cascader {
            flex: 1;
            margin-left: 12px;
        }
    }

    .row3 {
        background: #fff;
        border-radius: 2px;
        .row3-header-label {
            height: 80px;
            line-height: 80px;
            text-align: left;
            font-size: 16px;
            font-weight: bold;
            background: #FAFAFA;
            padding: 0 42px;
        }

        .row3-body-box {
            padding: 0 42px;

            .row3-body-search {
                height: 70px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-size: 14px; 

                .row3-body-search-btn {
                    span {
                        display: inline-block;
                        margin-left: 25px;
                        cursor: pointer;

                        i {
                            margin-right: 10px;
                        }

                        &:hover {
                            color: #0079C1;
                            text-decoration: underline;
                        }

                        &:last-child {
                            background: #0079C1;
                            color: #fff;
                            border-radius: 2px;
                            padding: 10px 20px;
                        }
                    }
                }
            }

            .row3-body-table {

                .row3-body-table-box {
                    border: 1px solid #F2F2F2;

                    /deep/ .el-table__header tr th {
                        background: #FAFAFA;
                    }

                    .row3-table-operation {
                        display: flex;
                        justify-content: space-around;
                        color: #0079C1;

                        i {
                            cursor: pointer;

                            &:hover {
                                opacity: 0.8;
                            }

                            &:last-child {
                                color: #FF4D4F;
                            }
                        }
                    }
                }
                .pagination {
                    margin-top: 20px;
                    text-align: right;
                    padding-bottom: 40px;
                    .paginationCount {
                        display: inline-block;
                        font-size: 14px;
                        font-weight: 400;
                        text-align: left;
                        color: #666666;
                    }
                    .paginationDiv {
                        display: inline-block;
                    }
                }
            }
        }
    }
}
.qrcode {
        font-size: 14px;
        font-weight: 400;
        color: #0079c1;
        cursor: pointer;
    }
.ksiComment {
   font-size: 12px;
   color: #909399;
}

