



































































































































































































































































































.type-box{
    width: 100%;
    position: relative;
    .top{
        height: 40px;
        margin: 10px 0 20px 0px;
        .top-title{
            text-align: left;
            font-weight: 700;
            font-size: 16px;
            margin-right:62px ;
            line-height: 40px;
            margin-left: 46px;
        }
        .top-class{
            margin-right: 13px;
            float: right;
            width: 118px;
            .top-class-text{
                height: 40px;
                font-size: 14px;
                line-height: 40px;
                background: #ffffff;
                border: 1px solid #dddddd;
                border-radius: 2px;
                cursor: pointer;
            }
            .active{
                color: #ffffff;
                background: #0079c1;
                border-radius: 2px 0px 0px 2px;
            }
        }
        
        .top-search{
            width: 368px;
            line-height: 40px;
            float: right;
            
        }
    }
    .content{
        box-sizing: border-box;
        position: relative;
        padding: 0 20px;
        .arrow{
            width: 47px;
            height: 100px;
            font-size: 30px;
            position: absolute;
            padding: 8px 8px;
            top: 50%;
            z-index: 10;
            transform: translateY(-50%);
            i{
                font-style: normal;
            }
        }
        .left{
            left:1px;
        }
        .right{
            right: 1px;
        }
        .widthcard{
            margin: 0 10px;
            cursor: pointer;
            &:hover {
                box-shadow: 0 0 15px #409EFF;
            }

            .imgDiv{
                height: 210px;
                background: #f2f2f2;
                border-radius: 2px;
                margin: 0 auto;
                .emptyimg {
                    display: block;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%) translateX(-50%);
                    left: 50%;
                }
                .typeimg{
                    width: 100%;
                    height: 210px;
                }
                    
            }
            .title{
                margin-top:23px ;
                width: 100%;
                font-size: 18px;
                font-weight: 700;
                text-align: left;
            }
            .version{
                width: 100%;
                margin-top:12px ;
                .version-tag{
                    cursor: pointer;
                    &:hover {
                        border-color: #3399FF;
                    }
                    min-height: 25px;
                    width: 100%;
                    border: 1px solid #999999;
                    margin-bottom: 10px;
                    float: left;
                    text-align: center;
                    .version-text{
                        height: 25px;
                        line-height: 25px;
                        font-size: 14px;
                        
                    }
                    
                }
                .voltage-tag{
                    line-height: 25px;
                    min-height: 25px;
                    width: 100%;
                    border: 1px solid #999999;
                    margin-bottom: 10px;
                    float: left;
                    text-align: center;
                    .voltage{
                        color: #00B194;
                    }
                }
            }
            .type-dec{
                word-wrap:break-word;
                font-size: 14px;
                font-weight: 400;
                text-align: left;
                color: #333333;
                width: 100%;
            }
        }



        .boxcard{
            cursor: pointer;
            margin-bottom: 10px;
            transition: all 0.4s;
            border-radius: 2px;
            width: 100%;
            &:hover {
                box-shadow: 0 0 15px #409EFF;
            }
            
            .info{
                box-sizing:border-box;
                width: 100%;
                height: 100%;
                display: block;
                position: relative;
                .imgDiv{
                    width: 160px;
                    height: 124px;
                    background: #f2f2f2;
                    border-radius: 2px;
                    margin-top: 8px;
                    margin-left: 8px;
                    .emptyimg {
                        display: block;
                        position: absolute;
                        margin-top: 31px;
                        margin-left: 37px;
                    }
                    .typeimg{
                        width: 160px;
                        height: 124px;
                    }
                    
                }
                .type-div{
                    margin-top: 8px;
                    .name{
                        font-size: 18px;
                        font-weight: 700;
                        text-align: left;
                        color: #333333;
                        text-align: left;
                        letter-spacing: 0.18px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                    .version{
                        width: 100%;
                        margin-top:12px ;
                        .version-tag{
                            cursor: pointer;
                            &:hover {
                                border-color: #3399FF;
                            }
                            height: 25px;
                            width: 271px;
                            border: 1px solid #999999;
                            margin-right:10px ;
                            margin-bottom: 10px;
                            float: left;
                            text-align: center;
                            .version-text{
                                height: 25px;
                                line-height: 25px;
                                font-size: 14px;
                                
                            }
                            
                        }
                        .voltage-tag{
                            line-height: 25px;
                            height: 25px;
                            width: 271px;
                            border: 1px solid #999999;
                            margin-right:10px ;
                            margin-bottom: 10px;
                            float: left;
                            text-align: center;
                            .voltage{
                                color: #00B194;
                            }
                        }
                    }
                    .type-dec{
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        font-size: 14px;
                        font-weight: 400;
                        text-align: left;
                        color: #333333;
                        width: 100%;
                    }
                    .operation{
                        float: right;
                        margin-right: 14px;
                        img{
                            cursor: pointer;
                        }
                    }
                }
                
            }
        }
        .more{
            height: 60px;
            line-height: 60px;
            text-align: center;
            background: #fff;
            font-size: 16px;
            color: #333;
            transition: all 0.4s;
            cursor: pointer;

            &:hover {
                box-shadow: 0 0 15px #409EFF;
            }
        }
    }
    .type-empty{
        position: absolute;
        transform:translateY(30%);
        width: 100%;
        .type-empty-text{
            font-size: 30px;
            font-family: Adobe Heiti Std, Adobe Heiti Std-R;
            font-weight: R;
            text-align: center;
            color: #999999;
            margin-top: 44px;
        }
        .type-empty-button{
            margin-top: 29px;
            font-size: 16px;
            .type-empty-link{
                color: #3394cd;
                cursor: pointer;
            }
        }
    }
}
.qrcode {
    font-size: 14px;
    font-weight: 400;
    color: #0079c1;
    cursor: pointer;
}

