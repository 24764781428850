












































































































































































.company-box {
    width: 100%;
    height: 100%;
    position: relative;

    p {
        margin: 0;
    }

    em {
        font-style: inherit;
    }

    .overall-box {
        width: 100%;
        height: 80px;
        line-height: 80px;
        // margin-left: 45px;
        .num-box {
            color: #333;
            font-size: 16px;
            line-height: 80px;
            text-align: left;
            float: left;
            margin-left: 45px;
            em {
                font-weight: bold;
                padding-right: 14px;
            }
        }

        .search-box {
            width: 40%;
            height: 38px;
            float: right;
        }
    }

    .list-box {
        .list {
            height: 136px;
            min-width: 756px;
            color: #666;
            background: #fff;
            text-align: left;
            font-size: 14px;
            line-height: 16px;
            margin-bottom: 10px;
            transition: all 0.4s;
            cursor: pointer;
            border-radius: 2px;
            &:hover {
                box-shadow: 0 0 15px #409EFF;
            }

            img {
                width: 83px;
                height: 83px;
                padding-left: 32px;
                padding-right: 45px;
                padding-top: 26px;
                display: block;
                float: left;
            }

            em {
                color: #333;
                font-weight: bold;
                font-size: 16px;
                padding-left: 13px;
            }

            .desc {
                display: block;
                /*float: left;*/

                .name {
                    padding-top: 40px;
                    padding-bottom: 30px;
                }

                .info {
                    p {
                        display: block;
                        float: left;
                        padding-right: 8%;

                        .color1 {
                            color: #0079C1;
                        }

                        .color2 {
                            color: #F4506B;
                        }

                        .color3 {
                            color: #00B194;
                        }
                        .color4 {
                            color: #909399;
                        }
                    }
                }
            }
        }

        .more{
            height: 60px;
            line-height: 60px;
            text-align: center;
            background: #fff;
            font-size: 16px;
            color: #333;
            transition: all 0.4s;
            cursor: pointer;

            &:hover {
                box-shadow: 0 0 15px #409EFF;
            }
        }
    }
}
