


























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.el-dialog-custom {
    text-align: left;
    font-weight: 700;
    /deep/ .el-dialog__header {
        padding: 20px 40px 10px;
        border-bottom: 1px solid #f2f2f2;
    }
    /deep/ .el-dialog__footer {
        text-align: center;
        padding: 20px;
        border-top: 1px solid #f2f2f2;
    }
}
.machine-control-box {
    position: relative;
    .row1 {
        .row1-header-box {
            height: 38px;
            .row1-header-btn {
                font-size: 14px;
                float: right;
                button{
                    // width: 130px;
                    height: 38px;
                }
            }
        }

        .row1-body-box {
            width: 100%;
            height: 509px;
            margin: 10px 0 10px;
        }
    }

    .row3 {
        background: #fff;
        border-radius: 2px;
        .row3-header-label {
            height: 60px;
            line-height: 60px;
            text-align: left;
            font-size: 16px;
            font-weight: bold;
        }

        .row3-body-box {
            .row3-body-table {
                .row3-body-table-box {
                    border: 1px solid #f2f2f2;

                    /deep/ .el-table__header tr th {
                        background: #fafafa;
                    }

                    .row3-table-operation {
                        display: flex;
                        justify-content: space-around;
                        color: #0079c1;

                        i {
                            cursor: pointer;

                            &:hover {
                                opacity: 0.8;
                            }

                            &:last-child {
                                color: #ff4d4f;
                            }
                        }
                    }
                }
                .pagination {
                    margin-top: 20px;
                    text-align: right;
                    padding-bottom: 40px;
                    .paginationCount {
                        display: inline-block;
                        font-size: 14px;
                        font-weight: 400;
                        text-align: left;
                        color: #666666;
                    }
                    .paginationDiv {
                        display: inline-block;
                    }
                }
            }
        }
    }

    .custom-config-box {
        text-align: center;
        .custom-config-text {
            margin-top: 30px;
            font-size: 18px;
            font-weight: bold;
        }
    }

    .custom-form-box {
        /deep/ .el-select {
            width: 70%;
        }
    }
}
