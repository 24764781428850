.emptyfault {
  padding: 15px 20px;
  height: 355px;
  text-align: center;
  font-size: 24px;
}
.emptyfault .el-icon-s-order {
  font-size: 100px;
  margin-top: 111px;
  margin-bottom: 10px;
  color: #ccc;
}
.emptyfault .faulttext {
  color: #0079c1;
  font-weight: 700;
}
.infofrmation-box p {
  margin: 0;
}
.infofrmation-box .marginBottom {
  margin-bottom: 20px;
}
.infofrmation-box .weight {
  font-weight: bold;
}
.infofrmation-box .qrcode {
  font-size: 14px;
  font-weight: 400;
  color: #0079c1;
  cursor: pointer;
}
.infofrmation-box .infofrmation-basic-box .infofrmation-basic-title {
  margin-bottom: 9px;
  height: 48px;
  line-height: 48px;
  background: #0079c1;
  border: 1px solid #0079c1;
  color: #fff;
  font-size: 16px;
  padding-left: 25px;
}
.infofrmation-box .infofrmation-basic-box .infofrmation-basic-table {
  margin-bottom: 9px;
}
.infofrmation-box .infofrmation-basic-box .infofrmation-basic-table table {
  width: 100%;
  border-bottom: 1px solid #cccccc;
}
.infofrmation-box .infofrmation-basic-box .infofrmation-basic-table table tr td {
  /*padding-left: 40px;*/
  padding-left: 2.5%;
  height: 48px;
  border: 1px solid #cccccc;
  border-right: 0;
  border-bottom: 0;
  box-sizing: border-box;
}
.infofrmation-box .infofrmation-basic-box .infofrmation-basic-table table tr td:last-child {
  border-right: 1px solid #cccccc;
}
.infofrmation-box .infofrmation-basic-box .infofrmation-basic-table table tr td.infofrmation-basic-table-img {
  width: 145px;
  padding: 0;
  position: relative;
  text-align: center;
}
.infofrmation-box .infofrmation-basic-box .infofrmation-basic-table table tr td.infofrmation-basic-table-img .car {
  width: 73px;
}
.infofrmation-box .infofrmation-basic-box .infofrmation-basic-table table tr td.infofrmation-basic-table-img .car-status {
  position: absolute;
  top: 20px;
  left: 20px;
  width: 51px;
  height: 52px;
}
.infofrmation-box .infofrmation-basic-box .infofrmation-basic-table table tr td .weight {
  margin-right: 10px;
}
.infofrmation-box .infofrmation-basic-box .infofrmation-basic-list {
  margin-bottom: 9px;
  height: 72px;
  border: 1px solid #cccccc;
  border-radius: 0px 2px 2px 0px;
}
.infofrmation-box .infofrmation-basic-box .infofrmation-basic-list .infofrmation-basic-list-icon {
  width: 146px;
  box-sizing: border-box;
  height: 100%;
  background: #F1F9FE;
  border-right: 1px solid #cccccc;
  display: flex;
  justify-content: center;
  align-items: center;
  float: left;
}
.infofrmation-box .infofrmation-basic-box .infofrmation-basic-list .infofrmation-basic-list-icon img {
  width: 59px;
  height: 60px;
}
.infofrmation-box .infofrmation-basic-box .infofrmation-basic-list .infofrmation-basic-list-content {
  float: left;
  font-size: 14px;
  color: #333;
  margin-top: 0.5%;
  /*padding-left: 9%;*/
  margin-left: 2.5%;
}
.infofrmation-box .infofrmation-basic-box .infofrmation-basic-list .infofrmation-basic-list-content .infofrmation-basic-list-title {
  /*float: left;*/
  color: #000;
  font-weight: bold;
  margin-bottom: 0.5%;
  /*padding-left: 47px;*/
  /*margin-left: 47px;*/
}
.infofrmation-box .infofrmation-basic-box .infofrmation-basic-list .infofrmation-basic-list-content .infofrmation-basic-list-desc p {
  /*float: left;*/
  /*margin-right: 40px;*/
  /*padding-left: 47px;*/
  margin-bottom: 0.2%;
}
.infofrmation-box .infofrmation-basic-footer {
  margin-top: 30px;
  height: 70px;
  background: #f2f2f2;
  border-top: 1px solid #dddddd;
  padding: 0px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.infofrmation-box .infofrmation-basic-footer .basic-footer-label {
  color: #333;
  font-size: 16px;
}
.infofrmation-box .infofrmation-basic-footer .basic-footer-label span:first-child {
  font-weight: bold;
  margin-right: 10px;
}
.infofrmation-box .infofrmation-basic-footer .basic-footer-btn {
  float: right;
}
.infofrmation-box .infofrmation-basic-footer .basic-footer-btn span {
  background: #ff9900;
  border-radius: 2px;
  color: #fff;
  display: inline-block;
  padding: 10px 15px;
  cursor: pointer;
}
.infofrmation-box .infofrmation-basic-footer .basic-footer-btn span:hover {
  opacity: 0.8;
}
.infofrmation-box .infofrmation-vehicle-box .infofrmation-vehicle-title {
  height: 48px;
  background: #fef5f7;
  border: 1px solid #fadada;
  border-radius: 2px;
  color: #F64040;
  background: url('../../assets/image/car/warning.png') 20px center no-repeat;
  background-color: #FEF5F7;
  background-size: 20px;
  box-sizing: border-box;
  padding-left: 60px;
  line-height: 48px;
}
.infofrmation-box .infofrmation-vehicle-box .infofrmation-vehicle-content {
  max-height: 355px;
  overflow-y: auto;
  margin: 30px auto;
}
.infofrmation-box .infofrmation-vehicle-box .infofrmation-vehicle-content .infofrmation-vehicle-list {
  margin-bottom: 10px;
}
.infofrmation-box .infofrmation-vehicle-box .infofrmation-vehicle-content .infofrmation-vehicle-list .infofrmation-vehicle-list-code {
  color: #F64040;
  font-size: 14px;
  margin-right: 10px;
  font-weight: bold;
}
.infofrmation-box .infofrmation-vehicle-box .infofrmation-vehicle-content .infofrmation-vehicle-list .infofrmation-vehicle-list-content {
  color: #333333;
  font-size: 14px;
}
.infofrmation-box .infofrmation-fault-box {
  height: 300px;
}
.infofrmation-box .infofrmation-fault-box .infofrmation-fault-pie-box {
  width: 40%;
  height: 100%;
  float: left;
}
.infofrmation-box .infofrmation-fault-box .infofrmation-fault-bar-box {
  width: 60%;
  height: 100%;
  float: right;
}
.infofrmation-box .infofrmation-latest-box {
  width: 100%;
  height: 350px;
}
.infofrmation-box .infofrmation-parameter-header {
  margin-right: 30px;
}
.infofrmation-box .infofrmation-parameter-header .collapse {
  min-width: 78px;
  margin-left: 15px;
}
.infofrmation-box .infofrmation-parameter-header span {
  width: 59px;
  text-align: center;
  font-size: 14px;
  color: #333;
  border: 1px solid #dddddd;
  border-radius: 2px;
  display: inline-block;
  padding: 5px 10px;
  cursor: pointer;
}
.infofrmation-box .infofrmation-parameter-header span.isSelected {
  color: #fff;
  background: #0079C1;
}
.infofrmation-box .infofrmation-parameter-header /deep/ .el-input__inner {
  margin-left: 10px;
  height: 30px;
  width: 100px;
}
.infofrmation-box .infofrmation-parameter-header /deep/ .el-select .el-input .el-select__caret {
  line-height: 30px;
}
.infofrmation-box .none-data-box {
  height: 512px;
  text-align: center;
  font-size: 24px;
}
.ksiComment {
  font-size: 12px;
  color: #909399;
}
