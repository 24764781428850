.scrolltop {
  background: url("../assets/image/zhiding01.png") no-repeat 0px 0px;
  cursor: pointer;
  width: 47px;
  height: 100px;
}
.scrolltop:hover {
  background: url("../assets/image/zhiding02.png") no-repeat 0px 0px;
}
.home {
  display: flex;
  flex-flow: nowrap;
  justify-content: flex-start;
  height: 100vh;
}
.nav-left {
  position: relative;
}
.menu-bottom-copyright {
  position: absolute;
  left: 15px;
  bottom: 15px;
}
.menu-bottom-copyright .text {
  letter-spacing: -0.5px;
  font-size: 9px;
  font-family: Arial, Arial-Regular;
  font-weight: 400;
  text-align: center;
  color: #006699;
}
.nav-right {
  background-color: #f2f2f2;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
}
.nav-header {
  height: 70px;
  min-height: 70px ;
  max-height: 70px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-bottom: solid 2px #e5e5e5;
  flex-shrink: 0;
}
.nav-header .nav-header-title {
  font-size: 16px;
}
.nav-content {
  background: #f2f2f2;
  flex-grow: 1;
  padding: 10px 10px 5px;
  margin: 0px 18px 16px 16px;
  border-radius: 0px;
  overflow: auto;
}
.nav-bottom {
  /* background: yellow; */
  height: 20px;
  padding: 10px 10px 5px;
  font-size: 12px;
  color: #000000;
}
