
























































































































































































































.login {
  display: flex;
  height: 100%;
  width: 100%;
  min-width:1232px ;
  min-height: 770px;
  background-color: #04223a;
}
@media screen and (min-width: 1920px) {
  .main {
    position: absolute;
    transform: translate(180%, 0);
    margin-left: 12%;
  }
}
@media screen and (max-width: 1919px) {
  .main {
    position: absolute;
    transform: translate(100%, 0);
    margin-left: 28%;
  }
}
.main {
  height: 100%;
  min-width: 533px;
  min-height: 770px;
  background-color: #f4f9fe;
}
.login_header {
  height: 200px;
  width: 100%;
  position: absolute;
  /*transform: translate(83px, 360px);*/
}
.change {
  cursor: pointer;
  width: 40px;
  height: 24px;
  line-height: 24px;
  border: 1px solid #cccccc;
  border-radius: 2px;
  float:right;
  margin: 22% 5%;
  text-align: center;
  font-weight: 700;
  font-size: 14px;
}
.login_logo {
  height: 80px;
  width: 300px;
  float:left;
  margin: 20% 7% 5% 6.5%;
}
img {
  height: 100%;
  width: 100%;
}
.login_header_up {
  width: 287px;
  height: 26px;
  font-size: 26px;
  font-family: Arial, Arial-Bold;
  font-weight: 700;
  text-align: left;
  color: #333333;
  position: absolute;
  /*transform: translate(83px, 250px);*/
  margin: 38% 0 0 7%;
}
.login_header_down {
  width: 227px;
  height: 17px;
  font-size: 17px;
  font-family: Arial, Arial-Regular;
  font-weight: 400;
  text-align: left;
  color: #666666;
  position: absolute;
  /*transform: translate(84px, 300px);*/
  margin: 47% 0 0 7%;
}
.form_Form {
  /*height: 315px;*/
  min-width: 350px;
  position: absolute;
  transform: translate(9.4%, 115%);
}
.form_username {
  min-width: 428px;
  float: inline-start;
}
.form_password {
  min-width: 428px;
  float: inline-start;
}
.form_option {
  height: 18px;
  width: 18px;
  margin-top: 5%;
}
.form_option_main {
  width: 82px;
  height: 13px;
  font-size: 16px;
  font-family: Microsoft YaHei, Microsoft YaHei-Regular;
  font-weight: 400;
  text-align: left;
  color: #666666;
}
.form_login {
  margin-top: 8%;
}
.el-button {
  min-width: 450px;
  height: 64px;
  border-radius: 3px;
  float: inline-start;
  margin-top: 8%;
}
.login_footer {
  width: 432px;
  height: 55px;
  font-size: 13px;
  font-family: Arial, Arial-Regular;
  font-weight: 400;
  text-align: center;
  color: #999999;
  line-height: 24px;
  position: absolute;
  /*transform: translate(22%, 2000%);*/
  bottom: 3%;
  left: 11%;
}
/deep/ .el-input__inner {
  height: 64px;
}
.link-style {
        font-size: 12px;
        font-weight: 400;
        color: #0079c1;
        cursor: pointer;
}
