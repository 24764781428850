








































































































































































































































































































































































































































































































































































































































.machineCard .el-card__body {
  padding: 0px !important;
}
.machineCard {
  border: 0px !important;
}
/*面包屑样式 */
.breadcrumbView {
  height: 30px;
  border-bottom: 1px solid #eaeef0;
  padding: 20px;
  margin-bottom: 20px;
  background-color: #fafafa;
}
.breadcrumbView .el-breadcrumb {
  vertical-align: middle;
  display: inline-block;
  width: 100%;
}
/*步骤条样式*/
.stepsView {
  padding: 0 12% 1% 12%;
  border-bottom: 1px solid #eaeef0;
  .el-step__icon-inner {
    font-weight: 400;
  }
  .is-finish .el-step__icon,
  .is-process .el-step__icon {
    background-color: #409eff;
    color: #fff;
    border: 3px solid #b3d7ed !important;
  }
  .el-step__title{
    font-size: 14px;
  }
  .is-wait .el-step__icon {
    background-color: #aebacb;
    color: #fff;
    border: 3px solid #e9ecf1 !important;
  }
  .is-finish {
    color: #333333 !important;
  }
  .is-process {
    font-weight: 400 !important;
  }
  .el-step__line {
    left: 58% !important;
    right: -42% !important;
    height: 4px !important;
    background: #e1e7ef;
    i{
      border: 0px solid transparent;
    }
  }
}
/*车辆信息样式 */
.machineBasicInfo-content {
  padding: 0 12% 1% 12%;
  margin-top: 3%;
  .el-select {
    display: block;
  }
  .el-form-item__label {
    padding-right: 20px;
  }
  /*电压单位样式 */
  .unit {
    margin: 0px;
    padding-right: 8px;
    color: #333333;
  }
  /*上传组件样式 */
  .el-upload--picture-card {
    width: 102px;
    height: 102px;
    line-height: 102px;
  }
  .el-upload-list__item {
    width: 102px !important;
    height: 102px !important;
  }
  .upload {
    display: inline-block;
    top: 0;
    position: absolute;
    left: 17px;
    padding-top: 20px;
    color: #555555;
    width: 70px;
  }
  .el-icon-plus {
    color: #555555;
    vertical-align: top;
    margin-top: 20px;
    font-size: 20px;
    line-height: 40px;
  }
  .hide .el-upload {
    display: none;
  }
  /*底部按钮样式 */
  .footer {
    text-align: left;
    .el-button {
      width: 110px;
      height: 38px;
    }
    .el-button--default:hover {
      background: transparent !important;
    }
    .el-button--primary {
      margin-left: 20px;
    }
  }
  /*隐藏数字框右边按钮 */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }
  /*弹窗标题样式 */
  .el-dialog__header {
    padding: 20px !important;
    padding-bottom: 10px !important;
    height: auto !important;
    text-align: left;
    background: #fafbfc;
    border-bottom: 1px solid #eaeef0;
  }
  /*重新选择图片上传组件样式 */
  .Rebulid {
    width: 100%;
    padding: 0px 14px 13px 14px;
    .el-upload {
      text-align: left;
      width: 100%;
    }
    .el-button {
      height: 34px;
      background: #ffffff;
      border: 1px solid #eaeef0;
      border-radius: 2px !important;
      margin-right: 11px;
      color: #0079c1;
    }
    .fileName {
      display: inline-block;
      color: #7a7a7a;
    }
  }
  /*裁剪框样式 */
  .cropper-content {
    .cropper-box {
      display: inline-block;
      width: 290px;
      .cropper {
        width: auto;
        height: 294px;
        .cropper-box {
          background: #898989;
        }
      }
    }
    /*预览区域样式 */
    .show-preview {
      height: 292px;
      width: 150px;
      text-align: center;
      border: 1px solid #eaeef0;
      display: inline-block;
      vertical-align: top;
      .previewsTitle {
        margin-top: 15px;
        color: #696969;
      }
      .preview {
        overflow: hidden;
        width: 100px !important;
        height: 100px !important;
        border-radius: 50px;
        border: 1px solid #0079c1;
        margin: 45px auto;
        display: inline-block;
      }
    }
  }
  .tip {
    padding: 0px 14px 13px 14px;
    width: 100%;
    text-align: left;
    color: #fd990d;
    margin-top: -10px;
  }
  /*弹窗底部按钮样式 */
  .footerButton {
    .el-button {
      width: 110px;
      height: 38px;
      background: #ffffff;
      border: 1px solid #cbcbcd;
      border-radius: 4px !important;
    }
    .el-button--primary {
      margin-left: 20px;
    }
  }
}
/*下拉框选项样式 */
.el-popper {
  .el-select-dropdown__item:hover {
    background-color: #fafafa;
  }
  .selected {
    font-weight: 400 !important;
    color: #3399ff !important;
    background-color: #ecf5ff;
  }
  .hover {
    background-color: #ecf5ff;
  }
}

input{
  border-radius: 3px;
}
/*输入框鼠标悬停添加阴影 */
input:hover,
textarea:hover,
.is-focus input {
  box-shadow: 0px 0px 8px 0px rgb(51 153 255 / 80%);
  border: 1px solid #3399ff !important;
  border-radius: 3px;
}
.el-input.is-disabled .el-input__inner{
  border-color: #ccc !important;
}
