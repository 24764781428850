.machine-detail-box {
  min-width: 867px;
}
.machine-detail-box .row1 {
  box-sizing: border-box;
  border-radius: 2px;
}
.machine-detail-box .row1 .row1-header-box {
  height: 70px;
  line-height: 70px;
  color: #000;
  background: #fafafa;
  padding: 0 42px;
}
.machine-detail-box .row1 .row1-header-box .row1-header-crumbs {
  font-size: 16px;
  font-weight: bold;
  float: left;
}
.machine-detail-box .row1 .row1-header-box .row1-header-crumbs .el-breadcrumb {
  vertical-align: middle;
  display: inline-block;
}
.machine-detail-box .row1 .row1-body-box {
  padding: 32px 40px;
  background: #FFFFFF;
}
.machine-detail-box .row1 .row1-body-box .row1-tab-box-3 {
  margin-bottom: 32px;
}
.machine-detail-box .row1 .row1-body-box .row1-tab-box-3 span {
  color: #666666;
  font-size: 14px;
  display: inline-block;
  height: 38px;
  background: #F2F2F2;
  width: calc(33.3% - 1px);
  line-height: 38px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  border-right: 1px solid #fff;
  user-select: none;
}
.machine-detail-box .row1 .row1-body-box .row1-tab-box-3 span.isSelected,
.machine-detail-box .row1 .row1-body-box .row1-tab-box-3 span:hover {
  background: #0079C1;
  color: #fff;
}
.machine-detail-box .row1 .row1-body-box .row1-tab-box-2 {
  margin-bottom: 32px;
}
.machine-detail-box .row1 .row1-body-box .row1-tab-box-2 span {
  color: #666666;
  font-size: 14px;
  display: inline-block;
  height: 38px;
  background: #F2F2F2;
  width: calc(50% - 1px);
  line-height: 38px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  border-right: 1px solid #fff;
  user-select: none;
}
.machine-detail-box .row1 .row1-body-box .row1-tab-box-2 span.isSelected,
.machine-detail-box .row1 .row1-body-box .row1-tab-box-2 span:hover {
  background: #0079C1;
  color: #fff;
}
.machine-detail-box .row1 .row1-body-box .row1-tab-box-1 {
  margin-bottom: 32px;
}
.machine-detail-box .row1 .row1-body-box .row1-tab-box-1 span {
  color: #666666;
  font-size: 14px;
  display: inline-block;
  height: 38px;
  background: #F2F2F2;
  width: calc(100% - 1px);
  line-height: 38px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  border-right: 1px solid #fff;
  user-select: none;
}
.machine-detail-box .row1 .row1-body-box .row1-tab-box-1 span.isSelected,
.machine-detail-box .row1 .row1-body-box .row1-tab-box-1 span:hover {
  background: #0079C1;
  color: #fff;
}
