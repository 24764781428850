.notification {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: 50px;
}
.notification i {
  color: #565d6a;
  font-size: 17px;
}
.notification i:hover {
  color: #0079c1;
}
.el-icon-message-solid {
  margin-right: 5px;
}
.header {
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
}
.contents {
  overflow-y: auto;
  overflow-x: hidden;
  width: 524px;
  height: 436px;
  margin-top: 2px;
  margin-bottom: 3px;
}
.footer {
  text-align: center;
}
.footer .more {
  cursor: pointer;
  color: #fff;
}
.notificationType-info {
  color: #ffffff;
}
.notificationType-warn {
  color: orange;
}
.notificationType-error {
  color: red;
}
.notice-content-main {
  margin: 12px 12px;
  width: 524px;
  height: 563px;
}
.el-menu-item {
  width: 174px;
  height: 18px;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  color: #333333;
}
.header-tip1 {
  font-size: 16px;
  font-weight: 700;
  text-align: left;
  color: #04223a;
  line-height: 16px;
}
.header-tip2 {
  font-size: 16px;
  font-weight: 400;
  text-decoration: underline;
  text-align: left;
  color: #0079c1;
}
.header-tip2:hover {
  cursor: pointer;
}
.custom-icon-error {
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: 13px;
  background-image: url("../../assets/image/error.png");
}
.custom-icon-warning {
  display: inline-block;
  width: 16px;
  height: 15px;
  margin-right: 11px;
  background-image: url("../../assets/image/warning.png");
}
.custom-icon-notice {
  display: inline-block;
  width: 16px;
  height: 14px;
  margin-right: 10px;
  background-image: url("../../assets/image/notice.png");
}
.el-button {
  width: 524px;
  height: 48px;
  background: #0079c1;
  border-radius: 2px;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  color: #f2f6fc;
}
.content-item {
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 2px;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 12px 0px 12px 24px;
}
.content-item-text {
  width: 430px;
  font-size: 16px;
  font-weight: 400;
  text-align: justify;
  color: #333333;
}
.content-item-text-error:hover {
  text-decoration: underline;
  color: #f34863;
  cursor: pointer;
}
.content-item-text-warning:hover {
  text-decoration: underline;
  color: #f6bd16;
  cursor: pointer;
}
.content-item-text-notice:hover {
  text-decoration: underline;
  color: #52c41a;
  cursor: pointer;
}
.content-item-time {
  font-size: 13px;
  font-weight: 400;
  text-align: left;
  margin-top: 12px;
  color: #666666;
}
.content-item-read {
  margin-top: 12px;
  margin-right: 12px;
  width: 24px;
  height: 24px;
  float: right;
  background-image: url("../../assets/image/unread.png");
}
.content-item-read:hover {
  cursor: pointer;
  background-image: url("../../assets/image/unread-hover.png");
}
