






































































































































































































































































































































































































































































































.qrcode {
    font-size: 14px;
    font-weight: 400;
    color: #0079c1;
    cursor: pointer;
}
.RDM-box{
    margin-top : 10px;
    background: #ffffff;
    border-radius: 2px;
    box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.10); 
    .RDM-top{
        border-bottom: 1px solid #e5e5e5;
        background: #fafafa;
        border-radius: 2px;
        padding: 0 18px;
        height: 79px;
        .title{
            font-size: 16px;
            font-weight: 700;
            line-height: 79px;
            float: left;
            margin-left:26px ;
        }
        .search{
            height: 48px;
            line-height: 79px;
        }
    }
    .RDM-content{
        padding: 20px;
        .RDM-content-top{
            float: left;
        }
        .RDM-table{
            margin-top: 21px;
            .table-box {
                border: 1px solid #F2F2F2;

                /deep/ .el-table__header tr th {
                    background: #FAFAFA;
                }

                .table-operation {
                    display: flex;
                    justify-content: space-around;
                    color: #0079C1;

                    i {
                        cursor: pointer;

                        &:hover {
                            opacity: 0.8;
                        }

                        &:last-child {
                            color: #FF4D4F;
                        }
                    }
                }
            }
            .pagination {
                margin-top: 20px;
                text-align: right;
                padding-bottom: 40px;
                .paginationCount {
                    display: inline-block;
                    font-size: 14px;
                    font-weight: 400;
                    text-align: left;
                    color: #666666;
                }
                .paginationDiv {
                    display: inline-block;
                }
            }
            .rdmon{
                    cursor: pointer;
                    width: 19px;
                    height: 19px;
                    margin:0;
                    background:url('../../assets/image/rdmon.png');
                }
            .point{
                cursor: pointer;
            }
            .gray{
                    font-size: 21px;
                    color: #999999;
                }

            .green{
                    font-size: 21px;
                    color: #00b194;
                }
            .red{
                    font-size: 21px;
                    color: #fe686a;
                }                
        }
    }
}
.network-icon-off{
    display: inline-block;
    width: 21px;
    height: 18px;
    margin:0 auto;
    background:url('../../assets/image/wifi_off.png');
}

.sw-config-icon-yes {
display: inline-block;
width: 16px;
height: 16px;
margin-right: 13px;
background-image: url("../../assets/image/config_1.png");
}
.sw-config-icon-no {
display: inline-block;
width: 16px;
height: 16px;
margin-right: 13px;
background-image: url("../../assets/image/config_2.png");
}
.process-backgroud
{
    background-color: #F2F2F2;
    border-radius: 6px;
}
