.factory-box {
  margin: 5% 10% 0;
  min-width: 758px;
}
.factory-box .factory-box-top {
  width: 100%;
  height: 101px;
  line-height: 101px;
  background: #0079c1;
  border-bottom: 4px solid #00B194;
  text-align: left;
}
.factory-box .factory-box-top .title {
  margin-left: 42px ;
  font-size: 30px;
  font-weight: 700;
  text-align: left;
  color: #ffffff;
}
.factory-box .factory-box-content {
  padding: 50px;
  background: #ffffff;
  border-radius: 2px;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1);
  text-align: left;
  font-size: 36px;
}
.factory-box .factory-box-content .factory-box-form /deep/ .el-form-item {
  margin-bottom: 40px;
}
.factory-box .factory-box-content .factory-box-form /deep/ .el-form-item__label {
  font-size: 36px;
  height: 64px;
  line-height: 64px;
}
.factory-box .factory-box-content .factory-box-form /deep/ .el-input__inner {
  font-size: 36px;
  height: 64px;
  line-height: 64px;
}
.factory-box .factory-box-content .factory-box-form /deep/ .el-textarea__inner {
  font-size: 36px;
  font-weight: 700;
}
.factory-box .factory-box-content .factory-box-form .el-button {
  font-size: 30px;
  height: 64px;
}
.factory-box .factory-box-content .factory-box-form .el-button + .el-button {
  margin-left: 18px;
}
