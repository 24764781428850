/*重新选择图片上传组件样式 */
.Rebulid {
  width: 100%;
  padding: 0px 14px 13px 14px;
}
.Rebulid .el-upload {
  text-align: left;
  width: 100%;
}
.Rebulid .el-button {
  height: 34px;
  background: #ffffff;
  border: 1px solid #eaeef0;
  border-radius: 2px !important;
  margin-right: 11px;
  color: #0079c1;
}
.Rebulid .fileName {
  display: inline-block;
  color: #7a7a7a;
}
/*裁剪框样式 */
.cropper-content {
  /*预览区域样式 */
}
.cropper-content .cropper-box {
  display: inline-block;
  width: 290px;
}
.cropper-content .cropper-box .cropper {
  width: auto;
  height: 294px;
}
.cropper-content .cropper-box .cropper .cropper-box {
  background: #898989;
}
.cropper-content .show-preview {
  height: 292px;
  width: 150px;
  text-align: center;
  border: 1px solid #eaeef0;
  display: inline-block;
  vertical-align: top;
}
.cropper-content .show-preview .previewsTitle {
  margin-top: 15px;
  color: #696969;
}
.cropper-content .show-preview .preview {
  overflow: hidden;
  width: 100px !important;
  height: 100px !important;
  border-radius: 50px;
  border: 1px solid #0079c1;
  margin: 45px auto;
  display: inline-block;
}
.tip {
  padding: 0px 14px 13px 14px;
  width: 100%;
  text-align: left;
  color: #fd990d;
}
/*弹窗底部按钮样式 */
.footerButton {
  text-align: center;
}
.el-dialog-custom {
  text-align: left;
  font-size: 14px;
  /*上传组件样式 */
}
.el-dialog-custom /deep/ .el-dialog__title {
  font-weight: 700;
  font-size: 16px;
}
.el-dialog-custom .el-input {
  width: 300px;
}
.el-dialog-custom .el-textarea {
  width: 555px;
}
.el-dialog-custom /deep/ .el-upload--picture-card {
  width: 102px;
  height: 102px;
  line-height: 102px;
}
.el-dialog-custom /deep/ .el-upload-list__item {
  width: 102px !important;
  height: 102px !important;
}
.el-dialog-custom /deep/ .upload {
  display: inline-block;
  top: 0;
  position: absolute;
  left: 17px;
  padding-top: 20px;
  color: #555555;
  width: 70px;
}
.el-dialog-custom /deep/ .el-icon-plus {
  color: #555555;
  vertical-align: top;
  margin-top: 20px;
  font-size: 20px;
  line-height: 40px;
}
.el-dialog-custom /deep/ .hide .el-upload {
  display: none;
}
.el-dialog-custom button {
  width: 110px;
  height: 38px;
}
.el-dialog-custom /deep/ .el-dialog__footer {
  text-align: center;
}
.type-box {
  width: 100%;
  position: relative;
}
.type-box .top {
  height: 40px;
  margin: 10px 0 20px 0px;
}
.type-box .top .top-title {
  text-align: left;
  font-weight: 700;
  font-size: 16px;
  margin-right: 62px ;
  line-height: 40px;
  margin-left: 46px;
}
.type-box .top .top-search {
  width: 368px;
  line-height: 40px;
  float: right;
}
.type-box .content {
  box-sizing: border-box;
}
.type-box .content .boxcard {
  margin-bottom: 10px;
  transition: all 0.4s;
  border-radius: 2px;
  width: 100%;
}
.type-box .content .boxcard:hover {
  box-shadow: 0 0 15px #409EFF;
}
.type-box .content .boxcard .info {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: block;
  position: relative;
}
.type-box .content .boxcard .info .imgDiv {
  width: 160px;
  height: 124px;
  background: #f2f2f2;
  border-radius: 2px;
  margin-top: 8px;
  margin-left: 8px;
}
.type-box .content .boxcard .info .imgDiv .emptyimg {
  display: block;
  position: absolute;
  margin-top: 31px;
  margin-left: 37px;
}
.type-box .content .boxcard .info .imgDiv .typeimg {
  width: 160px;
  height: 124px;
}
.type-box .content .boxcard .info .type-div {
  margin-top: 8px;
}
.type-box .content .boxcard .info .type-div .name {
  font-size: 18px;
  font-weight: 700;
  color: #333333;
  text-align: left;
  letter-spacing: 0.18px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.type-box .content .boxcard .info .type-div .version {
  width: 100%;
  margin-top: 12px ;
}
.type-box .content .boxcard .info .type-div .version .version-tag {
  cursor: pointer;
  height: 25px;
  width: 271px;
  border: 1px solid #999999;
  margin-right: 10px ;
  margin-bottom: 10px;
  float: left;
  text-align: center;
}
.type-box .content .boxcard .info .type-div .version .version-tag:hover {
  border-color: #3399FF;
}
.type-box .content .boxcard .info .type-div .version .version-tag .version-text {
  height: 25px;
  line-height: 25px;
  font-size: 14px;
}
.type-box .content .boxcard .info .type-div .version .voltage-tag {
  line-height: 25px;
  height: 25px;
  width: 271px;
  border: 1px solid #999999;
  margin-right: 10px ;
  margin-bottom: 10px;
  float: left;
  text-align: center;
}
.type-box .content .boxcard .info .type-div .version .voltage-tag .voltage {
  color: #00B194;
}
.type-box .content .boxcard .info .type-div .type-dec {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  color: #333333;
  width: 100%;
}
.type-box .content .boxcard .info .type-div .operation {
  float: right;
  margin-right: 14px;
}
.type-box .content .boxcard .info .type-div .operation img {
  cursor: pointer;
}
.type-box .content .more {
  height: 60px;
  line-height: 60px;
  text-align: center;
  background: #fff;
  font-size: 16px;
  color: #333;
  transition: all 0.4s;
  cursor: pointer;
}
.type-box .content .more:hover {
  box-shadow: 0 0 15px #409EFF;
}
.type-box .type-empty {
  position: absolute;
  transform: translateY(30%);
  width: 100%;
}
.type-box .type-empty .type-empty-text {
  font-size: 30px;
  font-family: Adobe Heiti Std, Adobe Heiti Std-R;
  font-weight: R;
  text-align: center;
  color: #999999;
  margin-top: 44px;
}
.type-box .type-empty .type-empty-button {
  margin-top: 29px;
  font-size: 16px;
}
.type-box .type-empty .type-empty-button .type-empty-link {
  color: #3394cd;
  cursor: pointer;
}
