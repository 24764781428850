.machineCard .el-card__body {
  padding: 0px !important;
}
.machineCard {
  border: 0px !important;
}
/*面包屑样式 */
.breadcrumbView {
  height: 30px;
  border-bottom: 1px solid #eaeef0;
  padding: 20px;
  margin-bottom: 20px;
  background-color: #fafafa;
}
.breadcrumbView .el-breadcrumb {
  vertical-align: middle;
  display: inline-block;
  width: 100%;
}
/*步骤条样式*/
.stepsView {
  padding: 0 12% 1% 12%;
  border-bottom: 1px solid #eaeef0;
}
.stepsView .el-step__icon-inner {
  font-weight: 400;
}
.stepsView .is-finish .el-step__icon,
.stepsView .is-process .el-step__icon {
  background-color: #409eff;
  color: #fff;
  border: 3px solid #b3d7ed !important;
}
.stepsView .el-step__title {
  font-size: 14px;
}
.stepsView .is-wait .el-step__icon {
  background-color: #aebacb;
  color: #fff;
  border: 3px solid #e9ecf1 !important;
}
.stepsView .is-finish {
  color: #333333 !important;
}
.stepsView .is-process {
  font-weight: 400 !important;
}
.stepsView .el-step__line {
  left: 58% !important;
  right: -42% !important;
  height: 4px !important;
  background: #e1e7ef;
}
.stepsView .el-step__line i {
  border: 0px solid transparent;
}
/*车辆信息样式 */
.machineBasicInfo-content {
  padding: 0 12% 1% 12%;
  margin-top: 3%;
  /*电压单位样式 */
  /*上传组件样式 */
  /*底部按钮样式 */
  /*隐藏数字框右边按钮 */
  /*弹窗标题样式 */
  /*重新选择图片上传组件样式 */
  /*裁剪框样式 */
  /*弹窗底部按钮样式 */
}
.machineBasicInfo-content .el-select {
  display: block;
}
.machineBasicInfo-content .el-form-item__label {
  padding-right: 20px;
}
.machineBasicInfo-content .unit {
  margin: 0px;
  padding-right: 8px;
  color: #333333;
}
.machineBasicInfo-content .el-upload--picture-card {
  width: 102px;
  height: 102px;
  line-height: 102px;
}
.machineBasicInfo-content .el-upload-list__item {
  width: 102px !important;
  height: 102px !important;
}
.machineBasicInfo-content .upload {
  display: inline-block;
  top: 0;
  position: absolute;
  left: 17px;
  padding-top: 20px;
  color: #555555;
  width: 70px;
}
.machineBasicInfo-content .el-icon-plus {
  color: #555555;
  vertical-align: top;
  margin-top: 20px;
  font-size: 20px;
  line-height: 40px;
}
.machineBasicInfo-content .hide .el-upload {
  display: none;
}
.machineBasicInfo-content .footer {
  text-align: left;
}
.machineBasicInfo-content .footer .el-button {
  width: 110px;
  height: 38px;
}
.machineBasicInfo-content .footer .el-button--default:hover {
  background: transparent !important;
}
.machineBasicInfo-content .footer .el-button--primary {
  margin-left: 20px;
}
.machineBasicInfo-content input::-webkit-outer-spin-button,
.machineBasicInfo-content input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
.machineBasicInfo-content input[type="number"] {
  -moz-appearance: textfield;
}
.machineBasicInfo-content .el-dialog__header {
  padding: 20px !important;
  padding-bottom: 10px !important;
  height: auto !important;
  text-align: left;
  background: #fafbfc;
  border-bottom: 1px solid #eaeef0;
}
.machineBasicInfo-content .Rebulid {
  width: 100%;
  padding: 0px 14px 13px 14px;
}
.machineBasicInfo-content .Rebulid .el-upload {
  text-align: left;
  width: 100%;
}
.machineBasicInfo-content .Rebulid .el-button {
  height: 34px;
  background: #ffffff;
  border: 1px solid #eaeef0;
  border-radius: 2px !important;
  margin-right: 11px;
  color: #0079c1;
}
.machineBasicInfo-content .Rebulid .fileName {
  display: inline-block;
  color: #7a7a7a;
}
.machineBasicInfo-content .cropper-content {
  /*预览区域样式 */
}
.machineBasicInfo-content .cropper-content .cropper-box {
  display: inline-block;
  width: 290px;
}
.machineBasicInfo-content .cropper-content .cropper-box .cropper {
  width: auto;
  height: 294px;
}
.machineBasicInfo-content .cropper-content .cropper-box .cropper .cropper-box {
  background: #898989;
}
.machineBasicInfo-content .cropper-content .show-preview {
  height: 292px;
  width: 150px;
  text-align: center;
  border: 1px solid #eaeef0;
  display: inline-block;
  vertical-align: top;
}
.machineBasicInfo-content .cropper-content .show-preview .previewsTitle {
  margin-top: 15px;
  color: #696969;
}
.machineBasicInfo-content .cropper-content .show-preview .preview {
  overflow: hidden;
  width: 100px !important;
  height: 100px !important;
  border-radius: 50px;
  border: 1px solid #0079c1;
  margin: 45px auto;
  display: inline-block;
}
.machineBasicInfo-content .tip {
  padding: 0px 14px 13px 14px;
  width: 100%;
  text-align: left;
  color: #fd990d;
  margin-top: -10px;
}
.machineBasicInfo-content .footerButton .el-button {
  width: 110px;
  height: 38px;
  background: #ffffff;
  border: 1px solid #cbcbcd;
  border-radius: 4px !important;
}
.machineBasicInfo-content .footerButton .el-button--primary {
  margin-left: 20px;
}
/*下拉框选项样式 */
.el-popper .el-select-dropdown__item:hover {
  background-color: #fafafa;
}
.el-popper .selected {
  font-weight: 400 !important;
  color: #3399ff !important;
  background-color: #ecf5ff;
}
.el-popper .hover {
  background-color: #ecf5ff;
}
input {
  border-radius: 3px;
}
/*输入框鼠标悬停添加阴影 */
input:hover,
textarea:hover,
.is-focus input {
  box-shadow: 0px 0px 8px 0px rgba(51, 153, 255, 0.8);
  border: 1px solid #3399ff !important;
  border-radius: 3px;
}
.el-input.is-disabled .el-input__inner {
  border-color: #ccc !important;
}
