














































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































/*车辆信息样式 */
.machineMonitorConfig-content{
  padding: 0 12% 1% 12%;
  .el-col{
    text-align: left;
  }
  .el-select {
    display: block;
  }
  .DefaultMonitor{
    color: #333333;
    font-weight: 700;
    padding: 20px 0 20px 20px;
    display: flex;
    .machine{
      line-height: 40px;
    }
    .search{
      flex: 0.3;
      margin-left: 10px;
      .unit {
        margin: 12px 10px;
        color: #666;
        font-size: 15px;
      }
    }
    .templateSelect{
      flex: 0.7;
      display: inline-block;
      margin-left: 10px;
    }
    .el-button{
      width: 110px;
      height: 38px;
    }
    .importButton{
      margin-left: 10px;
    }
  }
  .DefaultMonitorContent{
    background: #fafafa;
    border: 1px solid #f0f0f0;
    border-radius: 2px;
    padding: 15px 20px;
  }
  .emptyMonitor{
    background: #fafafa;
    border: 1px solid #f0f0f0;
    border-radius: 2px;
    padding: 15px 20px;
    height: 500px;
    text-align: center;
    font-size: 18px;
    .el-icon-s-order{
      font-size: 100px;
      margin-top: 150px;
      margin-bottom: 10px;
      color: #ccc;
    }
    .addMonitor{
      color: #047BC2;
      font-weight: 700;
    }
  }

  .el-table{
    margin-top: 20px;
    border: 1px solid #f0f0f0;
    width: 100%;
    .operation{
      cursor: pointer;
      display: inline-block;
      font-size: 18px;
      .el-icon-delete{
        margin-left: 20px;
        color: #FF4D4F;
      }
      .el-icon-edit{
        color: #047BC2;
      }
    }
    .el-table__expand-icon > .el-icon{
      display: none;
    }
    .el-table__expanded-cell{
      padding: 20px 25px;
      background: #fafafa;
    }
    tr:hover>td{
      background: #fff !important;
    }
    th{
      background-color: #fafafa;
      font-weight: 400;
      color: #333;
    }
    td{
      border-bottom: 1px solid #f0f0f0;
    }
  }
  .el-textarea__inner{
    padding: 10px 15px !important;
  }
  /*底部按钮样式 */
  .footer{
    text-align: center;
    margin: 30px 0;
    .el-button{
      width: 110px;
      height: 38px;
    }
    .el-button--default:hover{
      background: transparent !important;
    }
    .el-button--primary{
      margin-left: 20px;
    }
  }
  .border{
    padding-top: 20px;
    border-top: 1px solid #eaeef0;
    margin: 0 -2%;
  }
  .el-input.is-disabled .el-input__inner{
    border-color: #ccc;
  }
  /*弹窗底部按钮样式 */
  .dialogFooter{
    text-align: left;
    .el-button{
      width: 110px;
      height: 38px;
    }
    .el-button--default:hover{
      background: transparent !important;
    }
    .el-button--primary{
      margin-left: 20px;
    }
  }
  /*弹窗标题样式 */
  .el-dialog__header {
    padding: 20px !important;
    height: auto !important;
    text-align: left;
    background: #f2f2f2;
    border-bottom: 1px solid #eaeef0;
  }
  .mask{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: 11;
  }
}
/*Address下拉框样式*/
.addressPopover{
  width: 20%;
  padding: 20px !important;
  max-height: 300px;
  overflow: auto;
  .el-select__input{
    border: 0px none !important;
    box-shadow: transparent 0px 0px 0px 0px;
  }
  .el-select{
    width: 100%;
  }
  .unit {
    margin: 12px 10px;
    color: #666;
    font-size: 15px;
    z-index: 999;
  }
  .el-input__validateIcon{
    display: none;
  }
  .el-input__inner{
    border: 0px solid transparent;
    background: #f2f2f2;
  }
  .el-tree{
    margin: 10px 0px;
  }
  .el-tree-node__content:hover {
    background-color: transparent;
    color: #007ac2;
  }
  .el-tree--highlight-current .el-tree-node.is-current > .el-tree-node__content {
    background-color: #f4f6f9;
  }
}

