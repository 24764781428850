
























































































































































































































































































































































































 /*筛选条件下拉框布局*/
.companyHeaderDropdownList {
    width: 50%;
    display: inline-block;
    text-align: right;
}
.companyHeaderDropdown {
    display: inline-block;
    margin-left: 30px;
    float: right;
}
.PN-box{
    margin-top : 10px;
    background: #ffffff;
    border-radius: 2px;
    box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.10); 
    .PN-top{
        border-bottom: 1px solid #e5e5e5;
        background: #fafafa;
        border-radius: 2px;
        padding: 0 18px;
        height: 79px;
        .title{
            font-size: 16px;
            font-weight: 700;
            line-height: 79px;
            float: left;
            margin-left:26px ;
        }
        .search{
            height: 48px;
            line-height: 79px;
        }
    }
    .PN-content{
        padding: 20px;
        .PN-content-top{
            float: left;
        }
        .PN-table{
            margin-top: 21px;
            .table-box {
                border: 1px solid #F2F2F2;

                /deep/ .el-table__header tr th {
                    background: #FAFAFA;
                }

                .table-operation {
                    display: flex;
                    justify-content: space-around;
                    color: #0079C1;

                    i {
                        cursor: pointer;

                        &:hover {
                            opacity: 0.8;
                        }

                        &:last-child {
                            color: #FF4D4F;
                        }
                    }
                }
            }
            .pagination {
                margin-top: 20px;
                text-align: right;
                padding-bottom: 40px;
                .paginationCount {
                    display: inline-block;
                    font-size: 14px;
                    font-weight: 400;
                    text-align: left;
                    color: #666666;
                }
                .paginationDiv {
                    display: inline-block;
                }
            }
            .point{
                cursor: pointer;
            }
            .gray{
                    font-size: 21px;
                    color: #999999;
                }

            .green{
                    font-size: 21px;
                    color: #00b194;
                }
            .red{
                    font-size: 21px;
                    color: #fe686a;
                }                
        }
    }
}
