



























































































































































































































































































































































































































































































































































































































































































































































































































































































































































.emptyfault{
    padding: 15px 20px;
    height: 355px;
    text-align: center;
    font-size: 24px;
    .el-icon-s-order{
      font-size: 100px;
      margin-top: 111px;
      margin-bottom: 10px;
      color: #ccc;
    }
    .faulttext{
      color: #0079c1;
      font-weight: 700;
    }
  }
.infofrmation-box {
    p {
        margin: 0;
    }
    .marginBottom {
        margin-bottom: 20px;
    }

    .weight {
        font-weight: bold;
    }
    .qrcode {
        font-size: 14px;
        font-weight: 400;
        color: #0079c1;
        cursor: pointer;
    }
    .infofrmation-basic-box {
        .infofrmation-basic-title {
            margin-bottom: 9px;
            height: 48px;
            line-height: 48px;
            background: #0079c1;
            border: 1px solid #0079c1;
            color: #fff;
            font-size: 16px;
            padding-left: 25px;
        }

        .infofrmation-basic-table {
            margin-bottom: 9px;

            table {
                width: 100%;
                border-bottom: 1px solid #cccccc;

                tr {

                    td {
                        /*padding-left: 40px;*/
                        padding-left: 2.5%;
                        height: 48px;
                        border: 1px solid #cccccc;
                        border-right: 0;
                        border-bottom: 0;
                        box-sizing: border-box;

                        &:last-child {
                            border-right: 1px solid #cccccc;
                        }

                        &.infofrmation-basic-table-img {
                            width: 145px;
                            padding: 0;
                            position: relative;
                            text-align: center;

                            .car {
                                width: 73px;
                            }

                            .car-status {
                                position: absolute;
                                top: 20px;
                                left: 20px;
                                width: 51px;
                                height: 52px;
                            }
                        }

                        .weight {
                            margin-right: 10px;
                        }
                    }
                }
            }
        }

        .infofrmation-basic-list {
            margin-bottom: 9px;
            height: 72px;
            border: 1px solid #cccccc;
            border-radius: 0px 2px 2px 0px;

            .infofrmation-basic-list-icon {
                width: 146px;
                box-sizing: border-box;
                height: 100%;
                background: #F1F9FE;
                border-right: 1px solid #cccccc;
                display: flex;
                justify-content: center;
                align-items: center;
                float: left;

                img {
                    width: 59px;
                    height: 60px;
                }
            }

            .infofrmation-basic-list-content {               
                float: left;
                font-size: 14px;
                color: #333;
                margin-top: 0.5%;
                /*padding-left: 9%;*/
                margin-left: 2.5%;

                .infofrmation-basic-list-title {
                    /*float: left;*/
                    color: #000;
                    font-weight: bold;
                    margin-bottom: 0.5%;
                    /*padding-left: 47px;*/
                    /*margin-left: 47px;*/
                }
                .infofrmation-basic-list-desc {
                    p {
                        /*float: left;*/
                        /*margin-right: 40px;*/
                        /*padding-left: 47px;*/
                        margin-bottom: 0.2%;
                    }
                }
            }
        }
    }
    
    .infofrmation-basic-footer {
        margin-top: 30px;
        height: 70px;
        background: #f2f2f2;
        border-top: 1px solid #dddddd;
        padding: 0px 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .basic-footer-label {
            color: #333;
            font-size: 16px;

            span:first-child {
                font-weight: bold;
                margin-right: 10px;
            }
        }

        .basic-footer-btn {
            float: right;

            span {
                background: #ff9900;
                border-radius: 2px;
                color: #fff;
                display: inline-block;
                padding: 10px 15px;
                cursor: pointer;

                &:hover {
                    opacity: 0.8;
                }
            }
        }
    }

    .infofrmation-vehicle-box {
        .infofrmation-vehicle-title {
            height: 48px;
            background: #fef5f7;
            border: 1px solid #fadada;
            border-radius: 2px;
            color: #F64040;
            background: url('../../assets/image/car/warning.png') 20px center no-repeat;
            background-color: #FEF5F7;
            background-size: 20px;
            box-sizing: border-box;
            padding-left: 60px;
            line-height: 48px;
        }

        .infofrmation-vehicle-content {
            max-height: 355px;
            overflow-y: auto;
            margin: 30px auto;

            .infofrmation-vehicle-list {
                margin-bottom: 10px;
                .infofrmation-vehicle-list-code {
                    color: #F64040;
                    font-size: 14px;
                    margin-right: 10px;
                    font-weight: bold;
                }

                .infofrmation-vehicle-list-content {
                    color: #333333;
                    font-size: 14px;
                }
            }
        }
    }

    .infofrmation-fault-box {
        height: 300px;

        .infofrmation-fault-pie-box {
            width: 40%;
            height: 100%;
            float: left;
        }

        .infofrmation-fault-bar-box {
            width: 60%;
            height: 100%;
            float: right;
        }
    }

    .infofrmation-latest-box {
        width: 100%;
        height: 350px;
    }

    .infofrmation-parameter-header {
        margin-right: 30px;

        .collapse{
            min-width:78px;
            margin-left: 15px;
        }

        span {
            width: 59px;
            text-align: center;
            font-size: 14px;
            color: #333;
            border: 1px solid #dddddd;
            border-radius: 2px;
            display: inline-block;
            padding: 5px 10px;
            cursor: pointer;

            &.isSelected {
                color: #fff;
                background: #0079C1;
            }
        }

        /deep/.el-input__inner {
            margin-left: 10px;
            height: 30px;
            width: 100px;
        }

        /deep/.el-select .el-input .el-select__caret {
            line-height: 30px;
        }
    }

    .none-data-box{
        height: 512px;
        text-align: center;
        font-size: 24px;
    }
}
.ksiComment {
   font-size: 12px;
   color: #909399;
}
