.BatterySN {
  max-height: 300px;
  overflow-y: auto;
}
.row1 {
  background-color: #e5e5e5;
  height: 130px;
  display: flex;
  justify-content: center;
}
.row1 .online-state {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: space-around;
}
.row1 .online-state .carMain {
  display: inline-block;
}
.row1 .online-state .carMain .carMainTop {
  font-size: 30px;
  font-weight: 700;
  display: flex;
  flex-wrap: nowrap;
}
.row1 .online-state .carMain .carMainTop .carOnlineCount {
  width: 90px;
  text-align: center;
  color: #0079c1;
}
.row1 .online-state .carMain .carMainTop .carOfflineCount {
  width: 90px;
  text-align: center;
  color: #f34863;
}
.row1 .online-state .carMain .carMainTop .carCount {
  width: 90px;
  text-align: center;
  color: #04223a;
}
.row1 .online-state .carMain .carMainBottom {
  padding-top: 10px;
  height: 19px;
}
.row1 .online-state .carMain .carMainBottom .imgLogo {
  height: 19px;
  display: inline-block;
}
.row1 .online-state .carMain .carMainBottom .carMainText {
  height: 19px;
  font-size: 14px;
  line-height: 19px;
  font-weight: 400;
  text-align: center;
  color: #666666;
  margin-left: 6px;
  display: inline-block;
}
.row1 .online-state .carChart {
  width: 145px;
  height: 91px;
}
.row1 .RDM-state {
  border-left: #ffffff solid 1px;
  border-right: #ffffff solid 1px;
  border-color: rgba(0, 0, 0, 0.1);
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: space-around;
}
.row1 .RDM-state .RDMMain {
  display: inline-block;
}
.row1 .RDM-state .RDMMain .RDMMainTop {
  display: flex;
  flex-wrap: nowrap;
  font-size: 30px;
  font-weight: 700;
}
.row1 .RDM-state .RDMMain .RDMMainTop .rdm {
  width: 90px;
  height: 34px;
  text-align: center;
  color: #0079c1;
}
.row1 .RDM-state .RDMMain .RDMMainTop .unRdm {
  width: 90px;
  height: 34px;
  text-align: center;
  color: #04223a;
}
.row1 .RDM-state .RDMMain .RDMMainBottom {
  padding-top: 10px;
}
.row1 .RDM-state .RDMMain .RDMMainBottom .RDMMainText {
  height: 19px;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  color: #666666;
  display: inline-block;
  margin-left: 9px;
}
.row1 .RDM-state .RDMChart {
  width: 145px;
  height: 91px;
}
.row1 .Battery {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: space-around;
  border-right: #ffffff solid 1px;
  border-color: rgba(0, 0, 0, 0.1);
}
.row1 .Battery .low {
  display: inline-block;
}
.row1 .Battery .low .lowBatteryImg {
  display: inline-block;
  height: 33px;
  cursor: pointer;
}
.row1 .Battery .low .lowBatteryImg .battery-warning-count {
  display: inline-block;
  padding-left: 9px;
  text-align: justify;
  float: right;
  height: 30px;
  line-height: 30px;
  font-size: 30px;
  font-weight: 700;
  color: #ff9900;
}
.row1 .Battery .low .lowText {
  height: 13px;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  color: #666666;
}
.row1 .Battery .veryLow {
  display: inline-block;
  cursor: pointer;
}
.row1 .Battery .veryLow .veryLowBatteryImg {
  display: inline-block;
  height: 33px;
}
.row1 .Battery .veryLow .veryLowBatteryImg .battery-danger-count {
  display: inline-block;
  padding-left: 9px;
  float: right;
  height: 30px;
  line-height: 30px;
  font-size: 30px;
  font-weight: 700;
  color: #f34863;
}
.row1 .Battery .veryLow .veryLowText {
  height: 13px;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  color: #666666;
}
.row1 .faultStatus {
  display: flex;
  flex-grow: 1;
  justify-content: space-around;
}
.row1 .faultStatus .faultMain {
  display: inline-block;
  margin-top: 14px ;
  cursor: pointer;
  text-align: left;
}
.row1 .faultStatus .faultMain .faultImg {
  height: 59px;
  width: 123px;
  background: url('../../assets/image/fault bg.png');
}
.row1 .faultStatus .faultMain .faultImg .faultCount {
  width: 109px;
  height: 22px;
  font-size: 30px;
  font-weight: 700;
  text-align: center;
  color: #f2f2f2;
  line-height: 71px;
  cursor: pointer;
}
.row1 .faultStatus .faultMain .faultBottom {
  padding-top: 2px ;
  display: inline-block;
  width: 110px;
  text-align: center;
}
.row1 .faultStatus .faultMain .faultBottom .faultText {
  height: 19px;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  color: #666666;
  display: inline-block;
  margin-left: 9px;
}
.row1 .fault-warning {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background: #e94050;
  height: 100%;
  width: 250px;
}
.row1 .fault-warning .value {
  font-size: 36px;
  font-weight: bold;
}
.row1 .fault-warning .desc {
  font-size: 14px;
}
.row1 .fault-warning .icon {
  margin-left: 30px;
}
.row1 .fault-warning .icon i {
  font-size: 4rem;
  color: #fff;
}
.row2 {
  width: 100%;
  height: 301px;
  display: flex;
  margin: 16px 0px;
  justify-content: space-between;
  align-items: center;
}
.row2 .fault-statistic {
  flex-grow: 1;
  height: 100%;
}
.row2 .fault-statistic .faultTrendChart-main {
  background: #ffffff;
  width: 68.3%;
  height: 100%;
  float: left;
  border-radius: 2px;
  box-shadow: 0px 0px 1px 0px #ffffff;
}
.row2 .fault-statistic .faultTrendChart-main .faultTrendChart-header {
  margin-bottom: 34px;
  font-size: 16px;
  margin-top: 35px;
  margin-left: 30px;
  font-weight: 700;
  text-align: left;
  color: #333333;
}
.row2 .fault-statistic .faultTrendChart-main .faultTrendChart-header i {
  margin-left: 18px;
  width: 12px;
  height: 14px;
}
.row2 .fault-statistic .faultTrendChart-main .faultTrendChart-header .el-dropdown-link:hover {
  cursor: pointer;
}
.row2 .fault-statistic .faultTrendChart-main .faultTrendChart-header .faultTrendChart-time {
  float: right;
  margin-right: 30px;
}
.row2 .fault-statistic .faultTrendChart-main .faultTrendChart-header .faultTrendChart-time .el-range-editor.el-input__inner {
  border-radius: 25px;
}
.row2 .fault-statistic .faultTrendChart-main .faultTrendChart-header .faultTrendChart-time /deep/ .el-date-editor .el-range__icon {
  margin-left: 3px;
  line-height: 20px;
}
.row2 .fault-statistic .faultTrendChart-main .faultTrendChart-header .faultTrendChart-time /deep/ .el-date-editor .el-range__close-icon {
  line-height: 20px;
}
.row2 .fault-statistic .faultTrendChart-main .faultTrendChart-header .faultTrendChart-time /deep/ .el-date-editor .el-range-separator {
  line-height: 20px;
}
.row2 .fault-statistic .faultTrendChart-main .faultTrendChart {
  background: #ffffff;
  width: 95%;
  height: 70%;
}
.row2 .fault-statistic .faultCategoryChart-main {
  background: #ffffff;
  margin-left: 1%;
  width: 30%;
  height: 100%;
  float: right;
  box-shadow: 0px 0px 1px 0px #ffffff;
}
.row2 .fault-statistic .faultCategoryChart-main .faultCategoryChart-header {
  text-align: left;
  margin-bottom: 34px;
  font-size: 16px;
  margin-top: 35px;
  margin-left: 30px;
  font-weight: 700;
  color: #333333;
}
.row2 .fault-statistic .faultCategoryChart-main .faultCategoryChart-header .faultCategoryChart-time {
  float: right;
  margin-right: 30px;
}
.row2 .fault-statistic .faultCategoryChart-main .faultCategoryChart-header .faultCategoryChart-time .el-dropdown {
  font-size: 16px;
  font-weight: 400;
  color: #666666;
}
.row2 .fault-statistic .faultCategoryChart-main .faultCategoryChart-header .faultCategoryChart-time .el-dropdown :hover {
  cursor: pointer;
}
.row2 .fault-statistic .faultCategoryChart-main .faultCategoryChart {
  margin-left: 40px;
  width: 92%;
  height: 70%;
}
.row3 {
  margin: 16px 0px;
  display: block;
  justify-content: space-around;
  /*VehicleOverview部分布局*/
  /*VehicleOverview文本样式*/
  /*筛选条件下拉框布局*/
  /*map和CarList设置弹性布局*/
  /*地图布局*/
  /*CarList布局*/
  /*Car状态布局*/
  /*分页布局*/
  /*分页前总数文本信息*/
  /*故障图标*/
  /*故障车辆*/
}
.row3 .car-list .car-item {
  padding: 10px 10px;
  border: solid 1px #ddd;
  cursor: pointer;
  width: 44.5%;
  display: inline-block;
  text-align: center;
  margin: 0px 0px 1.5% 1.5%;
}
.row3 .car-detail .rdm {
  padding: 10px 10px;
  border: solid 1px #ddd;
  cursor: pointer;
}
.row3 .car-detail .controller {
  padding: 10px 10px;
  border: solid 1px #ddd;
  cursor: pointer;
}
.row3 .el-pager li.active,
.row3 .el-pagination.is-background .el-pager li:not(.disabled):hover {
  color: #0079c1 !important;
  border-color: #0079c1 !important;
  border: 1px #0079c1 solid;
}
.row3 .el-pager li {
  border: 1px #f4f4f5 solid;
  background-color: #fff !important;
}
.row3 .VehicleOverview {
  width: 98%;
  height: 388px;
  padding: 14px;
}
.row3 .carMapHeader {
  margin-bottom: 30px;
  padding-right: 5px;
}
.row3 .carMapHeaderTitle {
  font-size: 16px;
  font-weight: 700;
  text-align: left;
  display: inline-block;
  width: 50%;
}
.row3 .carMapHeaderDropdownList {
  width: 50%;
  display: inline-block;
  text-align: right;
}
.row3 .carMapHeaderDropdown {
  display: inline-block;
  margin-left: 30px;
}
.row3 .carMapAndCarList {
  display: flex;
}
.row3 .carMap {
  display: inline-block;
  flex: 1;
  height: 275px;
}
.row3 .carList {
  text-align: right;
  width: 66%;
  display: inline-block;
}
.row3 .carListSmall {
  text-align: right;
  width: 33%;
  display: inline-block;
}
.row3 .carListSmall .car-item {
  width: 95% !important;
}
.row3 .carList .car-item {
  width: 44.5% !important;
}
.row3 .carItemInfo {
  width: 32%;
  display: inline-block;
}
.row3 .carItemInfoStatus {
  color: #ff6666;
  text-align: right;
  width: 36%;
}
.row3 .carItemInfoStatusIconOffline {
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background: #ff6666;
  display: inline-block;
  margin-right: 10px;
}
.row3 .carItemInfoStatusIconOnline {
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background: #00b194;
  display: inline-block;
  margin-right: 10px;
}
.row3 .carItemInfoStatusTextOffline {
  display: inline-block;
  margin-right: 21px;
  width: 50px;
  text-align: center;
}
.row3 .carItemInfoStatusTextOnline {
  display: inline-block;
  margin-right: 21px;
  color: #00b194;
  width: 50px;
  text-align: center;
}
.row3 .pagination {
  text-align: center;
  margin-top: 40px;
}
.row3 .paginationCount {
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  color: #666666;
}
.row3 .paginationDiv {
  display: inline-block;
}
.row3 .warning {
  color: #faad14;
  margin-right: 5px;
  vertical-align: middle;
}
.row3 .error {
  background: #ffffff;
  border: 1px solid #ffccc7 !important;
  border-radius: 2px;
}
.row3 .car-item:hover {
  background: #fef3f3;
  border: 1px solid #ffccc7;
  border-radius: 2px;
}
.row3 .noVehicleView {
  margin-top: 105px;
  font-size: 18px;
  font-weight: 400;
  color: #a6a6a6;
}
.row3 .noVehicleView img {
  margin-bottom: 30px;
}
.row3 .mapboxgl-popup {
  max-width: 200px;
}
.row3 .mapboxgl-popup-content {
  text-align: center;
}
.row3 .carListNone {
  display: none;
}
.row3 .carListInfo {
  height: 250px;
}
.el-divider--horizontal {
  display: block;
  margin: 3px 0;
  width: 80%;
}
.el-divider {
  background-color: #f2f2f2;
}
 /deep/ .el-dialog {
  float: right;
  margin-top: 0 !important;
  margin-bottom: 0px;
  background: #04223a;
}
 /deep/ .el-dialog .el-dialog__header {
  background: #04223a !important;
  padding-bottom: 15px !important;
}
 /deep/ .el-dialog .el-dialog__headerbtn {
  right: 28px !important;
}
 /deep/ .el-dialog .el-dialog__body {
  height: 100%;
  min-height: 94.6vh;
  width: 466px;
  padding: 0;
}
.CarType {
  height: 15px;
  font-size: 17px;
  font-weight: 650;
  text-align: left;
  color: #f2f6fc;
  margin-left: 30px;
}
.image1 {
  width: 55px;
  height: 54px;
  margin-left: 39px;
  margin-top: 40px;
}
.RDM {
  margin-top: -50px;
  margin-left: 119px;
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  color: #0079c1;
}
.image2 {
  width: 55px;
  height: 54px;
  margin-left: 39px;
  margin-top: 40px;
}
.Programmable {
  margin-top: -50px;
  margin-left: 119px;
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  color: #0079c1;
}
.image3 {
  width: 55px;
  height: 54px;
  margin-left: 39px;
  margin-top: 40px;
}
.Motor {
  margin-top: -50px;
  margin-left: 119px;
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  color: #0079c1;
}
.Voltage-page {
  width: 410px;
  height: 316px;
  background: #f2f6fc;
  border-radius: 2px;
  margin-top: 59px;
  margin-left: 30px;
}
.Voltage-title {
  height: 15px;
  font-size: 15px;
  font-weight: 650;
  text-align: left;
  color: #333333;
  padding-top: 30px;
  padding-left: 21px;
}
.link {
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  color: #0079c1 !important;
  line-height: 48px;
  margin-left: 30px;
  margin-top: 48px;
}
.link a {
  text-decoration: none;
  color: #0079c1 !important;
}
.link a:hover {
  text-decoration: underline;
}
.question {
  margin-left: 20px;
  height: 18px;
  width: 18px;
  opacity: 0.4;
}
.question:hover {
  opacity: 1;
}
.carInfoRDM {
  color: #f2f6fc;
}
.gear {
  margin-left: 1%;
}
.monitorLineChart {
  width: 105%;
  height: 105%;
  margin-top: -3%;
}
.qrcode {
  font-size: 14px;
  font-weight: 400;
  color: #0079c1;
  cursor: pointer;
}
