.popBox-box {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #fdfdfd;
  opacity: 0.8;
}
.popBox-box .popBox-content-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  text-align: center;
  height: 300px;
  border-radius: 2px;
  box-sizing: border-box;
  padding-top: 30px;
  color: #000;
}
.popBox-box .popBox-content-box .popBox-content-img img {
  width: 123px;
}
.popBox-box .popBox-content-box /deep/ .el-progress-bar__outer {
  background: #EFEFEF;
}
.popBox-box .popBox-content-box .popBox-content-text .popBox-content-title {
  margin: 20px auto;
  font-size: 24px;
  color: #FF9A30;
}
.popBox-box .popBox-content-box .popBox-content-text .popBox-content-desc {
  font-size: 16px;
}
.popBox-box .popBox-content-box .popBox-content-text .popBox-content-loading {
  color: #00B395;
}
