

































































































































































































































































































































































































































































































.el-dialog-custom {
    
    font-size: 14px;
    /deep/ .el-dialog__header{
        text-align: left;
    }
    /deep/ .el-dialog__body{
        text-align: center;
    }
    /deep/ .el-dialog__title{
        font-weight: 700;
        font-size: 16px;
    }
    button {
        width: 110px;
        height: 38px;
    }
    .el-button + .el-button {
        margin-left: 20px;
    }
    .el-button + .el-button + .el-checkbox{
        margin-left: 20px;
    }
    /*上传组件样式*/
    .uploadFile{
        display: inline-block;
        width: 30%;
        margin-right: 2%;
    }
    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        width: 100%;
        height: 4vw;
        padding: 30% 0 20% 0;
    }
    .emptyFile .el-upload{
        border: 1px dashed #ff0000 !important;
    }

    .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
    }
    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 100%;
        height: 178px;
        text-align: center;
    }
    .avatar {
        width: 24%;
        height: 100%;
        display: inline-block;
    }
      .Add{
        width: 100%;
        line-height: 20px;
        font-size: 13px;
        font-family: Arial, Arial-Regular;
        font-weight: 400;
        text-align: center;
        color: #555555;
        margin-top:15% ;
        white-space: nowrap;
        text-overflow:ellipsis;
        overflow: hidden;
        padding-right: 10px;
    }
    /*上传组件中有文件时图片位置*/
    .el-icon-folder-opened{
        padding: 2.5px 0;
    }
    /*上传组件中有文件时文件名位置*/
    .haveFile{
        padding-top: 3px;
    }
    .upload{
        width: 100%;
        font-family: Arial, Arial-Regular;
        font-weight: 400;
        text-align: center;
        color: #555555;
    }
}
.el-table .default{
    height: 21px;
}
.soft-box{
    margin-top : 10px;
    background: #ffffff;
    border-radius: 2px;
    box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.10);
    .soft-top{
        border-bottom: 1px solid #e5e5e5;
        background: #fafafa;
        border-radius: 2px;
        padding: 0 18px;
        height: 79px;
        .title{
            font-size: 16px;
            font-weight: 700;
            line-height: 79px;
            float: left;
            margin-left:26px ;
        }
        .search{
            height: 48px;
            line-height: 79px;
        }
    }
    .soft-content{
        padding: 20px;
        .soft-content-top{
            float: left;
        }
        .soft-table{
            margin-top: 21px;
            .table-box {
                border: 1px solid #F2F2F2;

                /deep/ .el-table__header tr th {
                    background: #FAFAFA;
                }
                .green{
                    color: #00b194;
                }
                .table-operation {
                    display: flex;
                    justify-content: space-around;
                    color: #0079C1;

                    i {
                        cursor: pointer;

                        &:hover {
                            opacity: 0.8;
                        }

                        &:last-child {
                            color: #FF4D4F;
                        }
                    }
                }
            }
            .pagination {
                margin-top: 20px;
                text-align: right;
                padding-bottom: 40px;
                .paginationCount {
                    display: inline-block;
                    font-size: 14px;
                    font-weight: 400;
                    text-align: left;
                    color: #666666;
                }
                .paginationDiv {
                    display: inline-block;
                }
            }
        }
    }
}
