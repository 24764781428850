.emptyfault {
  padding: 15px 20px;
  height: 355px;
  text-align: center;
  font-size: 24px;
}
.emptyfault .el-icon-s-order {
  font-size: 100px;
  margin-top: 111px;
  margin-bottom: 10px;
  color: #ccc;
}
.emptyfault .faulttext {
  color: #0079c1;
  font-weight: 700;
}
.rdm-infofrmation-box p {
  margin: 0;
}
.rdm-infofrmation-box .marginBottom {
  margin-bottom: 20px;
}
.rdm-infofrmation-box .weight {
  font-weight: bold;
}
.rdm-infofrmation-box .qrcode {
  font-size: 14px;
  font-weight: 400;
  color: #0079c1;
  cursor: pointer;
}
.rdm-infofrmation-box .rdm-infofrmation-basic-box .rdm-infofrmation-basic-title {
  margin-bottom: 9px;
  height: 48px;
  line-height: 48px;
  background: #0079c1;
  border: 1px solid #0079c1;
  color: #fff;
  font-size: 16px;
  padding-left: 25px;
}
.rdm-infofrmation-box .rdm-infofrmation-basic-box .rdm-infofrmation-basic-table {
  margin-bottom: 9px;
}
.rdm-infofrmation-box .rdm-infofrmation-basic-box .rdm-infofrmation-basic-table table {
  width: 100%;
  border-bottom: 1px solid #cccccc;
}
.rdm-infofrmation-box .rdm-infofrmation-basic-box .rdm-infofrmation-basic-table table tr td {
  /*padding-left: 40px;*/
  padding-left: 2.5%;
  height: 48px;
  border: 1px solid #cccccc;
  border-right: 0;
  border-bottom: 0;
  box-sizing: border-box;
}
.rdm-infofrmation-box .rdm-infofrmation-basic-box .rdm-infofrmation-basic-table table tr td:last-child {
  border-right: 1px solid #cccccc;
}
.rdm-infofrmation-box .rdm-infofrmation-basic-box .rdm-infofrmation-basic-table table tr td.infofrmation-basic-table-img {
  width: 145px;
  padding: 0;
  position: relative;
  text-align: center;
}
.rdm-infofrmation-box .rdm-infofrmation-basic-box .rdm-infofrmation-basic-table table tr td.infofrmation-basic-table-img .car {
  width: 73px;
}
.rdm-infofrmation-box .rdm-infofrmation-basic-box .rdm-infofrmation-basic-table table tr td.infofrmation-basic-table-img .car-status {
  position: absolute;
  top: 20px;
  left: 20px;
  width: 51px;
  height: 52px;
}
.rdm-infofrmation-box .rdm-infofrmation-basic-box .rdm-infofrmation-basic-table table tr td .weight {
  margin-right: 10px;
}
.rdm-infofrmation-box .rdm-infofrmation-basic-box .rdm-infofrmation-basic-list {
  margin-bottom: 9px;
  height: 72px;
  border: 1px solid #cccccc;
  border-radius: 0px 2px 2px 0px;
}
.rdm-infofrmation-box .rdm-infofrmation-basic-box .rdm-infofrmation-basic-list .rdm-infofrmation-basic-list-icon {
  width: 146px;
  box-sizing: border-box;
  height: 100%;
  background: #F1F9FE;
  border-right: 1px solid #cccccc;
  display: flex;
  justify-content: center;
  align-items: center;
  float: left;
}
.rdm-infofrmation-box .rdm-infofrmation-basic-box .rdm-infofrmation-basic-list .rdm-infofrmation-basic-list-icon img {
  width: 59px;
  height: 60px;
}
.rdm-infofrmation-box .rdm-infofrmation-basic-box .rdm-infofrmation-basic-list .rdm-infofrmation-basic-list-content {
  float: left;
  font-size: 14px;
  color: #333;
  margin-top: 0.5%;
  /*padding-left: 9%;*/
  margin-left: 2.5%;
}
.rdm-infofrmation-box .rdm-infofrmation-basic-box .rdm-infofrmation-basic-list .rdm-infofrmation-basic-list-content .rdm-infofrmation-basic-list-title {
  /*float: left;*/
  color: #000;
  font-weight: bold;
  margin-bottom: 0.5%;
  /*padding-left: 47px;*/
  /*margin-left: 47px;*/
}
.rdm-infofrmation-box .rdm-infofrmation-basic-box .rdm-infofrmation-basic-list .rdm-infofrmation-basic-list-content .rdm-infofrmation-basic-list-desc p {
  /*float: left;*/
  /*margin-right: 40px;*/
  /*padding-left: 47px;*/
  margin-bottom: 0.2%;
}
.rdm-infofrmation-box .tb-backgroud {
  background-color: #F2F2F2;
  width: 20%;
}
.rdm-infofrmation-box .network-icon-off {
  display: inline-block;
  width: 21px;
  height: 18px;
  margin: 0 auto;
  background: url('../../assets/image/wifi_off.png');
}
.rdm-infofrmation-box .sw-config-icon-yes {
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: 13px;
  background-image: url("../../assets/image/config_1.png");
}
.rdm-infofrmation-box .sw-config-icon-no {
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: 13px;
  background-image: url("../../assets/image/config_2.png");
}
.rdm-infofrmation-box .gray {
  font-size: 21px;
  color: #999999;
}
.rdm-infofrmation-box .green {
  font-size: 21px;
  color: #00b194;
}
.rdm-infofrmation-box .red {
  font-size: 21px;
  color: #FF4D4F;
}
.rdm-infofrmation-box .process-backgroud {
  background-color: #F2F2F2;
  border-radius: 6px;
}
