


























































































































.machine-detail-box {
    min-width: 867px;
    .row1 {
        box-sizing: border-box;
        border-radius: 2px;
        .row1-header-box {
            height: 70px;
            line-height: 70px;
            color: #000;
            background: #fafafa;
            // display: flex;
            // justify-content: space-between;
            // align-items: center;
            padding: 0 42px;

            .row1-header-crumbs {
                font-size: 16px;
                font-weight: bold;
                float: left;

                
                .el-breadcrumb {
                    vertical-align: middle;
                    display: inline-block;
                }
            }
        }

        .row1-body-box {
            padding: 32px 40px;
            background: #FFFFFF;

            .row1-tab-box-3 {
                margin-bottom: 32px;
                span {
                    color: #666666;
                    font-size: 14px;
                    display: inline-block;
                    height: 38px;
                    background: #F2F2F2;
                    width: calc(33.3% - 1px);
                    line-height: 38px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    cursor: pointer;
                    border-right: 1px solid #fff;
                    user-select:none;
                    &.isSelected,
                    &:hover {
                        background: #0079C1;
                        color: #fff;
                    }
                }
            }
            .row1-tab-box-2 {
                margin-bottom: 32px;
                span {
                    color: #666666;
                    font-size: 14px;
                    display: inline-block;
                    height: 38px;
                    background: #F2F2F2;
                    width: calc(50% - 1px);
                    line-height: 38px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    cursor: pointer;
                    border-right: 1px solid #fff;
                    user-select:none;
                    &.isSelected,
                    &:hover {
                        background: #0079C1;
                        color: #fff;
                    }
                }
            }
            .row1-tab-box-1 {
                margin-bottom: 32px;
                span {
                    color: #666666;
                    font-size: 14px;
                    display: inline-block;
                    height: 38px;
                    background: #F2F2F2;
                    width: calc(100% - 1px);
                    line-height: 38px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    cursor: pointer;
                    border-right: 1px solid #fff;
                    user-select:none;
                    &.isSelected,
                    &:hover {
                        background: #0079C1;
                        color: #fff;
                    }
                }
            }

        }
    }
}
