.type-box {
  width: 100%;
  position: relative;
}
.type-box .top {
  height: 40px;
  margin: 10px 0 20px 0px;
}
.type-box .top .top-title {
  text-align: left;
  font-weight: 700;
  font-size: 16px;
  margin-right: 62px ;
  line-height: 40px;
  margin-left: 46px;
}
.type-box .top .top-class {
  margin-right: 13px;
  float: right;
  width: 118px;
}
.type-box .top .top-class .top-class-text {
  height: 40px;
  font-size: 14px;
  line-height: 40px;
  background: #ffffff;
  border: 1px solid #dddddd;
  border-radius: 2px;
  cursor: pointer;
}
.type-box .top .top-class .active {
  color: #ffffff;
  background: #0079c1;
  border-radius: 2px 0px 0px 2px;
}
.type-box .top .top-search {
  width: 368px;
  line-height: 40px;
  float: right;
}
.type-box .content {
  box-sizing: border-box;
  position: relative;
  padding: 0 20px;
}
.type-box .content .arrow {
  width: 47px;
  height: 100px;
  font-size: 30px;
  position: absolute;
  padding: 8px 8px;
  top: 50%;
  z-index: 10;
  transform: translateY(-50%);
}
.type-box .content .arrow i {
  font-style: normal;
}
.type-box .content .left {
  left: 1px;
}
.type-box .content .right {
  right: 1px;
}
.type-box .content .widthcard {
  margin: 0 10px;
  cursor: pointer;
}
.type-box .content .widthcard:hover {
  box-shadow: 0 0 15px #409EFF;
}
.type-box .content .widthcard .imgDiv {
  height: 210px;
  background: #f2f2f2;
  border-radius: 2px;
  margin: 0 auto;
}
.type-box .content .widthcard .imgDiv .emptyimg {
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
  left: 50%;
}
.type-box .content .widthcard .imgDiv .typeimg {
  width: 100%;
  height: 210px;
}
.type-box .content .widthcard .title {
  margin-top: 23px ;
  width: 100%;
  font-size: 18px;
  font-weight: 700;
  text-align: left;
}
.type-box .content .widthcard .version {
  width: 100%;
  margin-top: 12px ;
}
.type-box .content .widthcard .version .version-tag {
  cursor: pointer;
  min-height: 25px;
  width: 100%;
  border: 1px solid #999999;
  margin-bottom: 10px;
  float: left;
  text-align: center;
}
.type-box .content .widthcard .version .version-tag:hover {
  border-color: #3399FF;
}
.type-box .content .widthcard .version .version-tag .version-text {
  height: 25px;
  line-height: 25px;
  font-size: 14px;
}
.type-box .content .widthcard .version .voltage-tag {
  line-height: 25px;
  min-height: 25px;
  width: 100%;
  border: 1px solid #999999;
  margin-bottom: 10px;
  float: left;
  text-align: center;
}
.type-box .content .widthcard .version .voltage-tag .voltage {
  color: #00B194;
}
.type-box .content .widthcard .type-dec {
  word-wrap: break-word;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  color: #333333;
  width: 100%;
}
.type-box .content .boxcard {
  cursor: pointer;
  margin-bottom: 10px;
  transition: all 0.4s;
  border-radius: 2px;
  width: 100%;
}
.type-box .content .boxcard:hover {
  box-shadow: 0 0 15px #409EFF;
}
.type-box .content .boxcard .info {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: block;
  position: relative;
}
.type-box .content .boxcard .info .imgDiv {
  width: 160px;
  height: 124px;
  background: #f2f2f2;
  border-radius: 2px;
  margin-top: 8px;
  margin-left: 8px;
}
.type-box .content .boxcard .info .imgDiv .emptyimg {
  display: block;
  position: absolute;
  margin-top: 31px;
  margin-left: 37px;
}
.type-box .content .boxcard .info .imgDiv .typeimg {
  width: 160px;
  height: 124px;
}
.type-box .content .boxcard .info .type-div {
  margin-top: 8px;
}
.type-box .content .boxcard .info .type-div .name {
  font-size: 18px;
  font-weight: 700;
  color: #333333;
  text-align: left;
  letter-spacing: 0.18px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.type-box .content .boxcard .info .type-div .version {
  width: 100%;
  margin-top: 12px ;
}
.type-box .content .boxcard .info .type-div .version .version-tag {
  cursor: pointer;
  height: 25px;
  width: 271px;
  border: 1px solid #999999;
  margin-right: 10px ;
  margin-bottom: 10px;
  float: left;
  text-align: center;
}
.type-box .content .boxcard .info .type-div .version .version-tag:hover {
  border-color: #3399FF;
}
.type-box .content .boxcard .info .type-div .version .version-tag .version-text {
  height: 25px;
  line-height: 25px;
  font-size: 14px;
}
.type-box .content .boxcard .info .type-div .version .voltage-tag {
  line-height: 25px;
  height: 25px;
  width: 271px;
  border: 1px solid #999999;
  margin-right: 10px ;
  margin-bottom: 10px;
  float: left;
  text-align: center;
}
.type-box .content .boxcard .info .type-div .version .voltage-tag .voltage {
  color: #00B194;
}
.type-box .content .boxcard .info .type-div .type-dec {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  color: #333333;
  width: 100%;
}
.type-box .content .boxcard .info .type-div .operation {
  float: right;
  margin-right: 14px;
}
.type-box .content .boxcard .info .type-div .operation img {
  cursor: pointer;
}
.type-box .content .more {
  height: 60px;
  line-height: 60px;
  text-align: center;
  background: #fff;
  font-size: 16px;
  color: #333;
  transition: all 0.4s;
  cursor: pointer;
}
.type-box .content .more:hover {
  box-shadow: 0 0 15px #409EFF;
}
.type-box .type-empty {
  position: absolute;
  transform: translateY(30%);
  width: 100%;
}
.type-box .type-empty .type-empty-text {
  font-size: 30px;
  font-family: Adobe Heiti Std, Adobe Heiti Std-R;
  font-weight: R;
  text-align: center;
  color: #999999;
  margin-top: 44px;
}
.type-box .type-empty .type-empty-button {
  margin-top: 29px;
  font-size: 16px;
}
.type-box .type-empty .type-empty-button .type-empty-link {
  color: #3394cd;
  cursor: pointer;
}
.qrcode {
  font-size: 14px;
  font-weight: 400;
  color: #0079c1;
  cursor: pointer;
}
