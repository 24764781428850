.RDM-box {
  margin-top: 10px;
  background: #ffffff;
  border-radius: 2px;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1);
}
.RDM-box .RDM-top {
  border-bottom: 1px solid #e5e5e5;
  background: #fafafa;
  border-radius: 2px;
  padding: 0 18px;
  height: 79px;
}
.RDM-box .RDM-top .title {
  font-size: 16px;
  font-weight: 700;
  line-height: 79px;
  float: left;
  margin-left: 26px ;
}
.RDM-box .RDM-top .search {
  height: 48px;
  line-height: 79px;
}
.RDM-box .RDM-content {
  padding: 20px;
}
.RDM-box .RDM-content .RDM-content-top {
  float: left;
}
.RDM-box .RDM-content .RDM-table {
  margin-top: 21px;
}
.RDM-box .RDM-content .RDM-table .table-box {
  border: 1px solid #F2F2F2;
}
.RDM-box .RDM-content .RDM-table .table-box /deep/ .el-table__header tr th {
  background: #FAFAFA;
}
.RDM-box .RDM-content .RDM-table .table-box .rdmon {
  cursor: pointer;
  width: 19px;
  height: 19px;
  margin: 0 auto;
  background: url('../../assets/image/rdmon.png');
}
.RDM-box .RDM-content .RDM-table .table-box .point {
  cursor: pointer;
}
.RDM-box .RDM-content .RDM-table .table-box .wifi-off {
  width: 21px;
  height: 18px;
  margin: 0 auto;
  background: url('../../assets/image/wifi_off.png');
}
.RDM-box .RDM-content .RDM-table .table-box .gray {
  font-size: 21px;
  color: #999999;
}
.RDM-box .RDM-content .RDM-table .table-box .green {
  font-size: 21px;
  color: #00b194;
}
.RDM-box .RDM-content .RDM-table .table-box .red {
  font-size: 21px;
  color: #fe686a;
}
.RDM-box .RDM-content .RDM-table .pagination {
  margin-top: 20px;
  text-align: right;
  padding-bottom: 40px;
}
.RDM-box .RDM-content .RDM-table .pagination .paginationCount {
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  color: #666666;
}
.RDM-box .RDM-content .RDM-table .pagination .paginationDiv {
  display: inline-block;
}
