/*车辆信息样式 */
.machineMonitorConfig-content {
  padding: 0 12% 1% 12%;
  /*底部按钮样式 */
  /*弹窗底部按钮样式 */
  /*弹窗标题样式 */
}
.machineMonitorConfig-content .el-col {
  text-align: left;
}
.machineMonitorConfig-content .el-select {
  display: block;
}
.machineMonitorConfig-content .DefaultMonitor {
  color: #333333;
  font-weight: 700;
  padding: 20px 0 20px 20px;
  display: flex;
}
.machineMonitorConfig-content .DefaultMonitor .machine {
  line-height: 40px;
}
.machineMonitorConfig-content .DefaultMonitor .search {
  flex: 0.3;
  margin-left: 10px;
}
.machineMonitorConfig-content .DefaultMonitor .search .unit {
  margin: 12px 10px;
  color: #666;
  font-size: 15px;
}
.machineMonitorConfig-content .DefaultMonitor .templateSelect {
  flex: 0.7;
  display: inline-block;
  margin-left: 10px;
}
.machineMonitorConfig-content .DefaultMonitor .el-button {
  width: 110px;
  height: 38px;
}
.machineMonitorConfig-content .DefaultMonitor .importButton {
  margin-left: 10px;
}
.machineMonitorConfig-content .DefaultMonitorContent {
  background: #fafafa;
  border: 1px solid #f0f0f0;
  border-radius: 2px;
  padding: 15px 20px;
}
.machineMonitorConfig-content .emptyMonitor {
  background: #fafafa;
  border: 1px solid #f0f0f0;
  border-radius: 2px;
  padding: 15px 20px;
  height: 500px;
  text-align: center;
  font-size: 18px;
}
.machineMonitorConfig-content .emptyMonitor .el-icon-s-order {
  font-size: 100px;
  margin-top: 150px;
  margin-bottom: 10px;
  color: #ccc;
}
.machineMonitorConfig-content .emptyMonitor .addMonitor {
  color: #047BC2;
  font-weight: 700;
}
.machineMonitorConfig-content .el-table {
  margin-top: 20px;
  border: 1px solid #f0f0f0;
  width: 100%;
}
.machineMonitorConfig-content .el-table .operation {
  cursor: pointer;
  display: inline-block;
  font-size: 18px;
}
.machineMonitorConfig-content .el-table .operation .el-icon-delete {
  margin-left: 20px;
  color: #FF4D4F;
}
.machineMonitorConfig-content .el-table .operation .el-icon-edit {
  color: #047BC2;
}
.machineMonitorConfig-content .el-table .el-table__expand-icon > .el-icon {
  display: none;
}
.machineMonitorConfig-content .el-table .el-table__expanded-cell {
  padding: 20px 25px;
  background: #fafafa;
}
.machineMonitorConfig-content .el-table tr:hover > td {
  background: #fff !important;
}
.machineMonitorConfig-content .el-table th {
  background-color: #fafafa;
  font-weight: 400;
  color: #333;
}
.machineMonitorConfig-content .el-table td {
  border-bottom: 1px solid #f0f0f0;
}
.machineMonitorConfig-content .el-textarea__inner {
  padding: 10px 15px !important;
}
.machineMonitorConfig-content .footer {
  text-align: center;
  margin: 30px 0;
}
.machineMonitorConfig-content .footer .el-button {
  width: 110px;
  height: 38px;
}
.machineMonitorConfig-content .footer .el-button--default:hover {
  background: transparent !important;
}
.machineMonitorConfig-content .footer .el-button--primary {
  margin-left: 20px;
}
.machineMonitorConfig-content .border {
  padding-top: 20px;
  border-top: 1px solid #eaeef0;
  margin: 0 -2%;
}
.machineMonitorConfig-content .el-input.is-disabled .el-input__inner {
  border-color: #ccc;
}
.machineMonitorConfig-content .dialogFooter {
  text-align: left;
}
.machineMonitorConfig-content .dialogFooter .el-button {
  width: 110px;
  height: 38px;
}
.machineMonitorConfig-content .dialogFooter .el-button--default:hover {
  background: transparent !important;
}
.machineMonitorConfig-content .dialogFooter .el-button--primary {
  margin-left: 20px;
}
.machineMonitorConfig-content .el-dialog__header {
  padding: 20px !important;
  height: auto !important;
  text-align: left;
  background: #f2f2f2;
  border-bottom: 1px solid #eaeef0;
}
.machineMonitorConfig-content .mask {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 11;
}
/*Address下拉框样式*/
.addressPopover {
  width: 20%;
  padding: 20px !important;
  max-height: 300px;
  overflow: auto;
}
.addressPopover .el-select__input {
  border: 0px none !important;
  box-shadow: transparent 0px 0px 0px 0px;
}
.addressPopover .el-select {
  width: 100%;
}
.addressPopover .unit {
  margin: 12px 10px;
  color: #666;
  font-size: 15px;
  z-index: 999;
}
.addressPopover .el-input__validateIcon {
  display: none;
}
.addressPopover .el-input__inner {
  border: 0px solid transparent;
  background: #f2f2f2;
}
.addressPopover .el-tree {
  margin: 10px 0px;
}
.addressPopover .el-tree-node__content:hover {
  background-color: transparent;
  color: #007ac2;
}
.addressPopover .el-tree--highlight-current .el-tree-node.is-current > .el-tree-node__content {
  background-color: #f4f6f9;
}
