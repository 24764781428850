





























































































































































































































































































































































































































































































/*车辆信息样式 */
.machineFaultConfig-content{
  padding: 0 12% 1% 12%;
  margin-top: 3%;
  .el-col{
    text-align: left;
  }

  .el-tabs--card > .el-tabs__header{
    border-bottom: 1px solid #eee;
    .el-tabs__nav {
      border: 0px solid;
    }
    .el-tabs__item.is-active {
      background: #047bc2;
      color: #fff;
    }
    .el-tabs__item {
      border-left: 0px solid;
      margin-right: 10px;
      background: #f5f6f7;
      color: #999;
      border-radius: 2px 2px 0px 0px;
    }
  }
  .device{
    font-weight: 700;
    line-height: 40px;
    .count{
      color:#0066CC;
      font-weight: 700;
    }
  }
  .unit {
    margin: 12px 10px;
    color: #666;
    font-size: 15px;
  }
  .el-table{
    margin-top: 20px;
    border: 1px solid #f0f0f0;
    width: 100%;
    .operation{
      display: inline-block;
      font-size: 20px;
      .el-icon-edit{
        color: #047BC2;
      }
      .el-icon-document{
        color: #00b194;
      }
    }
    .el-table__expand-icon > .el-icon{
      display: none;
    }
    .el-table__expanded-cell{
      padding: 20px 25px;
      background: #fafafa;
    }
    th{
      background-color: #fafafa;
      font-weight: 400;
      color: #333;
    }
    td{
      border-bottom: 1px solid #f0f0f0;
    }
  }
  .el-textarea__inner{
    padding: 10px 15px !important;
  }
  #qrCode{
    display: none;
  }
  /*底部按钮样式 */
  .footer{
    text-align: center;
    margin: 30px 0;
    .el-button{
      width: 110px;
      height: 38px;
    }
    .el-button--default:hover{
      background: transparent !important;
    }
    .el-button--primary{
      margin-left: 20px;
    }
  }
  .border{
    padding-top: 20px;
    border-top: 1px solid #eaeef0;
    margin: 0 -2%;
  }
  .el-input.is-disabled .el-input__inner{
    border-color: #ccc;
  }
  /*弹窗底部按钮样式 */
  .dialogFooter{
    text-align: left;
    .el-button{
      width: 110px;
      height: 38px;
    }
    .el-button--default:hover{
      background: transparent !important;
    }
    .el-button--primary{
      margin-left: 20px;
    }
  }
  /*弹窗标题样式 */
  .el-dialog__header {
    padding: 20px !important;
    height: auto !important;
    text-align: left;
    background: #f2f2f2;
    border-bottom: 1px solid #eaeef0;
  }
}
