



















































































































































.icon-box {
    text-align: left;
    .icon-title-box {
        font-size: 14px;
        color: #333;
        margin: 20px 0;
        font-weight: bold;
    }

    .icon-content-box {

        &::after {
            content: '';
            display: block;
            overflow: hidden;
            width: 0;
            height: 0;
            clear: both;
        }
        .icon-list-box {
            padding: 25px 40px;
            box-sizing: border-box;
            border: 1px solid #dddddd;
            border-radius: 2px;
            height: 100px;
            margin-bottom: 12px;
            margin-right: 12px;
            float: left;
            width: calc(25% - 10px);

            &:nth-child(5n) {
                margin-right: 0;
            }
            .icon-list-content {
                height: 39px;

                .icon-list-content-value {
                    display: inline-block;
                    height: 30px;
                    line-height: 30px;
                    font-size: 30px;
                    font-weight: bold;
                    color: #333;

                    span {
                        font-size: 14px;
                        font-weight: 100;
                    }
                }
                .icon-list-content-value {
                    display: inline-block;
                    height: 36px;
                    line-height: 30px;
                    font-size: 30px;
                    font-weight: bold;
                    color: #333;

                    span {
                        font-size: 14px;
                        font-weight: 100;
                    }
                }
            }
            .icon-list-content-desc {
                font-size: 14px;
                font-weight: 400;
                color: #666666;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
}
