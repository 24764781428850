
























































































































































.assign-box{
    margin-top : 10px;
    background: #ffffff;
    border-radius: 2px;
    box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.10); 
    .assign-top{
        border-bottom: 1px solid #e5e5e5;
        background: #fafafa;
        border-radius: 2px;
        padding: 0 18px;
        .header-crumbs {
            margin-left: 26px;
            font-size: 16px;
            font-weight: bold;
            float: left;
            height: 79px;
            line-height: 79px;
            .el-breadcrumb {
                vertical-align: middle;
                display: inline-block;
            }
        }
    }
    .assign-content{
        padding: 0 40px 60px;
        .assign-content-top{
            height: 55px;
            .title{
                font-size: 14px;
                float: left;
                line-height: 55px;
                color: #333333;
            }
            .search{
                float: right;
                line-height: 55px;
                width: 100%;
            }
            .company-box{
                text-align: left;
                max-height: 700px;
                border: 1px solid #cccccc;
                overflow-y: auto;
                /deep/.el-radio {
                    display: block;
                    margin: 10px auto;
                }
                .content{
                    padding: 20px;
                }
                .foot{
                    height: 78px;
                    border-top: 1px solid #cccccc;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    button {
                        width: 110px;
                        height: 38px;
                    }
                    .el-button + .el-button {
                        margin-left: 20px;
                    }
                }
            }
            
        }
        
    }
}
