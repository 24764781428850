.main {
  margin: 28px 32px 40px 41px;
}
.header {
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid #e5e5e5;
}
.page {
  background-color: #ffffff;
  min-width: 1013px;
}
.tab {
  display: flex;
}
.tab_item {
  display: flex;
  border-radius: 2px 2px 0px 0px;
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 140px;
  height: 38px;
  margin-right: 4px;
  background-color: #f2f2f2;
  color: #666666;
  cursor: pointer;
}
.tab_item:hover {
  color: #fff;
  background: #0079c1;
}
.isChecked {
  background-color: #0079c1;
  color: #ffffff;
}
.btu {
  display: flex;
}
.btu .msg-btu {
  align-items: center;
  display: flex;
  margin-left: 47px;
  background-repeat: no-repeat;
  background-size: contain;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: flex-end;
}
.btu .msg-btu .mask-icon {
  display: flex;
  min-width: 15.5px;
  min-height: 15.5px;
  margin-right: 6.5px;
  background-image: url("../../assets/image/mark all@2x.png");
  background-repeat: no-repeat;
  background-size: contain;
}
.btu .msg-btu .btu_mask_all {
  display: flex;
  flex: 1;
  width: 130px;
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  color: #0079c1;
  line-height: 39px;
  cursor: pointer;
}
.btu .msg-btu .btu_mask_all:hover {
  text-decoration: underline;
}
.btu .msg-btu .btu_delete_all {
  width: 80px;
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  color: #ff0000;
  line-height: 39px;
  cursor: pointer;
}
.btu .msg-btu .btu_delete_all:hover {
  text-decoration: underline;
}
.btu .msg-btu .delete-icon {
  display: flex;
  min-width: 15.5px;
  min-height: 15.5px;
  margin-right: 5px;
  background-image: url("../../assets/image/detele@2x.png");
  background-repeat: no-repeat;
  background-size: contain;
}
 /deep/ is-active {
  background-color: #d50c66;
}
.page-title {
  height: 70px;
  display: flex;
  align-items: center;
}
.page-title .title-ch {
  display: flex;
  width: 90px;
  height: 22px;
  font-size: 18px;
  line-height: 22px;
  font-weight: 700;
  margin-left: 44px;
}
.page-title .title-en {
  display: flex;
  width: 160px;
  height: 22px;
  font-size: 18px;
  line-height: 22px;
  font-weight: 700;
  margin-left: 44px;
}
.page-title .time .el-range-editor.el-input__inner {
  border-radius: 25px;
}
.page-title .time /deep/ .el-date-editor .el-range__icon {
  margin-left: 3px;
  line-height: 20px;
}
.page-title .time /deep/ .el-date-editor .el-range__close-icon {
  line-height: 20px;
}
.page-title .time /deep/ .el-date-editor .el-range-separator {
  line-height: 20px;
}
.contents {
  margin-top: 20px;
  margin-left: 25px;
  min-height: 540px;
}
.contents .serial {
  position: absolute;
  left: -25px;
  top: 40px;
  height: 32px;
  width: 32px;
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  color: #0079c1;
  line-height: 32px;
}
.contents .read {
  color: #999999;
}
.contents .el-card {
  min-height: 94px;
  background-color: #f2f2f2;
  border: 1px solid #e5e5e5;
  border-radius: 2px;
}
.contents .el-card .msg-icon {
  display: flex;
  align-self: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.contents .el-card .info {
  display: flex;
  width: 45px;
  height: 38px;
  background-image: url("../../assets/image/notice@2x.png");
}
.contents .el-card .warning {
  display: flex;
  width: 47px;
  height: 44px;
  background-image: url("../../assets/image/warning@2x.png");
}
.contents .el-card .error {
  display: flex;
  width: 45px;
  height: 45px;
  background-image: url("../../assets/image/error@2x.png");
}
.contents .el-card .msg-main {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 16px;
}
.contents .el-card .msg-main .msg-contents {
  display: flex;
  max-width: 885px;
  font-size: 18px;
  font-weight: 400;
  text-align: left;
  color: #333333;
}
.contents .el-card .msg-main .msg-time {
  margin-top: 16px;
  display: flex;
  width: 122px;
  font-size: 16px;
  font-weight: 400;
  text-align: right;
  color: #666666;
}
.contents .el-card .msg-mask {
  left: 28px;
  width: 98%;
  height: 100%;
  position: absolute;
  top: 0px;
  display: none;
  background-color: rgba(0, 121, 193, 0.8);
  color: #eeeeee;
  font-size: 20px;
}
.contents .el-card .msg-mask .msg-mask-true {
  width: 64px;
  height: 64px;
  background-image: url("../../assets/image/mark  big@2x.png");
  background-repeat: no-repeat;
  background-size: contain;
  margin-right: 36px;
}
.contents .el-card .msg-mask .msg-mask-delete {
  width: 64px;
  height: 64px;
  background-image: url("../../assets/image/detele big@2x.png");
  background-repeat: no-repeat;
  background-size: contain;
}
.contents .el-card__body:hover .msg-mask {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.contents .more {
  margin-left: 70px;
  text-align: center;
  line-height: 60px;
  height: 60px;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 2px;
}
.contents .more:hover {
  cursor: pointer;
}
.contents .no-msg {
  height: 540px;
}
.no-msg {
  display: flex;
  flex: 1;
  height: 670px;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border-radius: 2px;
}
.no-msg .icon {
  display: flex;
  height: 125px;
  width: 135px;
  background-image: url("../../assets/image/message all@2x.png");
  background-repeat: no-repeat;
  background-size: contain;
}
.no-msg .tip {
  display: flex;
  height: 28px;
  font-size: 30px;
  font-weight: 400;
  text-align: center;
  color: #999999;
}
 /deep/ .el-card__body {
  display: flex;
  flex: 1;
}
 /deep/ .el-timeline-item__node {
  background-color: #e5f1f9;
}
 /deep/ .el-timeline-item__node--normal {
  height: 32px;
  left: -25px;
  width: 32px;
  top: 37px;
}
 /deep/ .el-timeline-item__tail {
  left: -10px;
  border-left: 2px solid #e5e5e5;
  height: 180%;
}
