.el-dialog-custom {
  text-align: left;
  font-size: 14px;
}
.el-dialog-custom /deep/ .el-dialog__title {
  font-size: 14px;
  font-weight: 700;
}
.el-dialog-custom /deep/ .el-input {
  width: 300px;
}
.el-dialog-custom /deep/ .el-textarea {
  width: 555px;
}
.el-dialog-custom button {
  width: 110px;
  height: 38px;
}
.el-dialog-custom /deep/ .el-dialog__footer {
  text-align: center;
}
.fault-box {
  min-width: 883px;
  margin-top: 10px;
  background: #ffffff;
  border-radius: 2px;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1);
}
.fault-box .fault-top {
  border-bottom: 1px solid #e5e5e5;
  background: #fafafa;
  border-radius: 2px;
  padding: 0 18px;
}
.fault-box .fault-top .title {
  font-size: 16px;
  font-weight: 700;
  line-height: 70px;
  float: left;
  margin-left: 26px ;
}
.fault-box .fault-top .search {
  height: 48px;
  line-height: 70px;
}
.fault-box .fault-top .datepicker {
  height: 48px;
  line-height: 70px;
  margin-right: 10px ;
}
.fault-box .fault-top .datepicker /deep/ .el-date-editor {
  width: 100%;
}
.fault-box .fault-top .selectMachine {
  line-height: 70px;
  height: 48px;
  margin-right: 10px ;
}
.fault-box .fault-top .selectMachine .el-select {
  width: 100%;
}
.fault-box .fault-content {
  padding: 20px;
}
.fault-box .fault-content .fault-content-top {
  height: 58px;
  border-bottom: 1px solid #e5e5e5;
  position: relative;
}
.fault-box .fault-content .fault-content-top .tab {
  position: absolute;
  float: left;
  bottom: 1px;
}
.fault-box .fault-content .fault-content-top .tab span {
  width: 200px;
  height: 38px;
  background: #f2f2f2;
  border-radius: 2px 2px 0px 0px;
  font-size: 14px;
  cursor: pointer;
  display: inline-block;
  line-height: 38px;
  margin: 0 1.5px;
}
.fault-box .fault-content .fault-content-top .tab span.active {
  color: #fff;
  background: #0079C1;
}
.fault-box .fault-content .fault-content-top .tab span:hover {
  color: #fff;
  background: #0079C1;
}
.fault-box .fault-content .fault-content-top .button {
  float: right;
}
.fault-box .fault-content .fault-content-top .button .el-button {
  height: 38px;
}
.fault-box .fault-content .fault-list {
  margin-top: 20px ;
}
.fault-box .fault-content .fault-list .fault-list-row {
  margin-bottom: 10px;
}
.fault-box .fault-content .fault-list .fault-list-row .fault-title {
  min-height: 56px;
  background: #fafafa;
  border: 1px solid #dddddd;
  border-radius: 2px 2px 0px 0px;
  line-height: 56px;
}
.fault-box .fault-content .fault-list .fault-list-row .fault-title:hover {
  box-shadow: 0 0 15px #409EFF;
}
.fault-box .fault-content .fault-list .fault-list-row .fault-title .fault-active {
  margin-left: 23px;
  margin-top: 13px;
  width: 93px;
  height: 30px;
  background: rgba(255, 0, 0, 0.1);
  border-radius: 15px;
  color: #FF0000;
  font-size: 14px;
  line-height: 30px;
  float: left;
}
.fault-box .fault-content .fault-list .fault-list-row .fault-title .fault-resolved {
  margin-left: 23px;
  margin-top: 13px;
  width: 93px;
  height: 30px;
  background: rgba(0, 177, 148, 0.1);
  border-radius: 15px;
  color: #00B194;
  font-size: 14px;
  line-height: 30px;
  float: left;
}
.fault-box .fault-content .fault-list .fault-list-row .fault-title .fault-code {
  font-size: 16px;
  font-weight: 700;
  color: #333333;
  float: left;
  margin-left: 21px;
}
.fault-box .fault-content .fault-list .fault-list-row .fault-title .fault-name {
  font-size: 16px;
  margin-left: 43px;
  float: left;
}
.fault-box .fault-content .fault-list .fault-list-row .fault-title .fault-date {
  font-size: 16px;
  color: #999999;
  float: right;
  margin-right: 23px;
}
.fault-box .fault-content .fault-list .fault-list-row .fault-title .fault-eidt {
  padding-top: 3px;
  height: 19px;
  width: 19px;
  float: right;
  margin-right: 36px;
}
.fault-box .fault-content .fault-list .fault-list-row .fault-title .fault-eidt img {
  cursor: pointer;
}
.fault-box .fault-content .fault-list .fault-list-row .fault-dec {
  border: 1px solid #dddddd;
  border-top: 0;
  border-radius: 0px 0px 2px 2px;
}
.fault-box .fault-content .fault-list .fault-list-row .fault-dec .fault-info {
  padding: 25px 32px 25px 25px;
  font-size: 14px;
  border-right: 1px solid #dddddd;
}
.fault-box .fault-content .fault-list .fault-list-row .fault-dec .fault-info .el-row {
  margin-bottom: 2px;
}
.fault-box .fault-content .fault-list .fault-list-row .fault-dec .fault-info .info-title {
  width: 100%;
  height: 30px;
  line-height: 30px;
  background: #e5f1f9;
  border-radius: 12px 0px 0px 12px;
  color: #0079c1;
  float: right;
  margin-right: 3px ;
}
.fault-box .fault-content .fault-list .fault-list-row .fault-dec .fault-info .info-result {
  padding-left: 9px;
  width: 100%;
  line-height: 30px;
  height: 30px;
  background: #fafafa;
  float: left;
  text-align: left;
}
.fault-box .fault-content .fault-list .fault-list-row .fault-dec .row-right {
  padding: 25px 32px 25px 25px;
}
.fault-box .fault-content .fault-list .fault-list-row .fault-dec .row-right .row-right-title {
  font-size: 14px;
  font-weight: 700;
  text-align: left;
}
.fault-box .fault-content .fault-list .fault-list-row .fault-dec .row-right .row-right-remark {
  word-wrap: break-word;
  font-size: 14px;
  text-align: left;
}
.fault-box .fault-content .fault-list .more {
  height: 60px;
  background: #fafafa;
  border: 1px solid #dddddd;
  border-radius: 2px;
  text-align: center;
  font-size: 16px;
  line-height: 60px;
  cursor: pointer;
}
.fault-box .fault-content .fault-list .more:hover {
  box-shadow: 0 0 15px #409EFF;
}
