







































.card-box {
    border: 1px solid #dddddd;
    border-radius: 2px;
    text-align: left;

    .card-header-box {
        padding-top: 35px;
        margin-bottom: 17px;
        
        .card-header-title {
            font-size: 16px;
            color: #333333;
            padding-left: 30px;
            font-weight: bold;
            background: url('../../assets/image/icon.png') left center no-repeat;
        }

        .card-header-custom {
            float: right;
        }
    }

    .card-body-box {
        padding: 0px 30px;
    }
}
