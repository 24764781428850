































































































































































































































































































































































































































































































































































































































































































/*重新选择图片上传组件样式 */
.Rebulid {
    width: 100%;
    padding: 0px 14px 13px 14px;
    .el-upload {
    text-align: left;
    width: 100%;
    }
    .el-button {
    height: 34px;
    background: #ffffff;
    border: 1px solid #eaeef0;
    border-radius: 2px !important;
    margin-right: 11px;
    color: #0079c1;
    }
    .fileName {
    display: inline-block;
    color: #7a7a7a;
    }
}
/*裁剪框样式 */
.cropper-content {
    .cropper-box {
    display: inline-block;
    width: 290px;
    .cropper {
        width: auto;
        height: 294px;
        .cropper-box {
        background: #898989;
        }
    }
    }
    /*预览区域样式 */
    .show-preview {
    height: 292px;
    width: 150px;
    text-align: center;
    border: 1px solid #eaeef0;
    display: inline-block;
    vertical-align: top;
    .previewsTitle {
        margin-top: 15px;
        color: #696969;
    }
    .preview {
        overflow: hidden;
        width: 100px !important;
        height: 100px !important;
        border-radius: 50px;
        border: 1px solid #0079c1;
        margin: 45px auto;
        display: inline-block;
    }
    }
}
.tip {
    padding: 0px 14px 13px 14px;
    width: 100%;
    text-align: left;
    color: #fd990d;
}

/*弹窗底部按钮样式 */
.footerButton {
    text-align: center;
}
.el-dialog-custom {
    text-align: left;
    font-size: 14px;
    /deep/ .el-dialog__title{
        font-weight: 700;
        font-size: 16px;
    }
    .el-input {
        width: 300px;
    }
    .el-textarea{
        width: 555px;
    }
    /*上传组件样式 */
    /deep/ .el-upload--picture-card {
        width: 102px;
        height: 102px;
        line-height: 102px;
    }
    /deep/ .el-upload-list__item {
        width: 102px !important;
        height: 102px !important;
    }
    /deep/ .upload {
        display: inline-block;
        top: 0;
        position: absolute;
        left: 17px;
        padding-top: 20px;
        color: #555555;
        width: 70px;
    }
    /deep/ .el-icon-plus {
        color: #555555;
        vertical-align: top;
        margin-top: 20px;
        font-size: 20px;
        line-height: 40px;
    }
    /deep/ .hide .el-upload {
        display: none;
    }
    button {
        width: 110px;
        height: 38px;
    }
    /deep/ .el-dialog__footer{
        text-align: center;
    }
}
.type-box{
    width: 100%;
    position: relative;
    .top{
        height: 40px;
        margin: 10px 0 20px 0px;
        .top-title{
            text-align: left;
            font-weight: 700;
            font-size: 16px;
            margin-right:62px ;
            line-height: 40px;
            margin-left: 46px;
        }
        .top-search{
            width: 368px;
            line-height: 40px;
            float: right;
        }
    }
    .content{
        box-sizing: border-box;
        .boxcard{
            margin-bottom: 10px;
            transition: all 0.4s;
            border-radius: 2px;
            width: 100%;
            &:hover {
                box-shadow: 0 0 15px #409EFF;
            }
            
            .info{
                box-sizing:border-box;
                width: 100%;
                height: 100%;
                display: block;
                position: relative;
                .imgDiv{
                    width: 160px;
                    height: 124px;
                    background: #f2f2f2;
                    border-radius: 2px;
                    margin-top: 8px;
                    margin-left: 8px;
                    .emptyimg {
                        display: block;
                        position: absolute;
                        margin-top: 31px;
                        margin-left: 37px;
                    }
                    .typeimg{
                        width: 160px;
                        height: 124px;
                    }
                    
                }
                .type-div{
                    margin-top: 8px;
                    .name{
                        font-size: 18px;
                        font-weight: 700;
                        text-align: left;
                        color: #333333;
                        text-align: left;
                        letter-spacing: 0.18px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                    .version{
                        width: 100%;
                        margin-top:12px ;
                        .version-tag{
                            cursor: pointer;
                            &:hover {
                                border-color: #3399FF;
                            }
                            height: 25px;
                            width: 271px;
                            border: 1px solid #999999;
                            margin-right:10px ;
                            margin-bottom: 10px;
                            float: left;
                            text-align: center;
                            .version-text{
                                height: 25px;
                                line-height: 25px;
                                font-size: 14px;
                                
                            }
                            
                        }
                        .voltage-tag{
                            line-height: 25px;
                            height: 25px;
                            width: 271px;
                            border: 1px solid #999999;
                            margin-right:10px ;
                            margin-bottom: 10px;
                            float: left;
                            text-align: center;
                            .voltage{
                                color: #00B194;
                            }
                        }
                    }
                    .type-dec{
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        font-size: 14px;
                        font-weight: 400;
                        text-align: left;
                        color: #333333;
                        width: 100%;
                    }
                    .operation{
                        float: right;
                        margin-right: 14px;
                        img{
                            cursor: pointer;
                        }
                    }
                }
                
            }
        }
        .more{
            height: 60px;
            line-height: 60px;
            text-align: center;
            background: #fff;
            font-size: 16px;
            color: #333;
            transition: all 0.4s;
            cursor: pointer;

            &:hover {
                box-shadow: 0 0 15px #409EFF;
            }
        }
    }
    .type-empty{
        position: absolute;
        transform:translateY(30%);
        width: 100%;
        .type-empty-text{
            font-size: 30px;
            font-family: Adobe Heiti Std, Adobe Heiti Std-R;
            font-weight: R;
            text-align: center;
            color: #999999;
            margin-top: 44px;
        }
        .type-empty-button{
            margin-top: 29px;
            font-size: 16px;
            .type-empty-link{
                color: #3394cd;
                cursor: pointer;
            }
        }
    }
}

