















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.el-dialog-custom{
    text-align: left;
    font-size: 14px;
    /deep/.el-dialog__title{
        font-size: 14px;
        font-weight: 700;
    }
    /deep/.el-input{
        width: 300px;
    }
    /deep/.el-textarea{
        width: 555px;
    }
     button {
        width: 110px;
        height: 38px;
    }
    /deep/ .el-dialog__footer{
        text-align: center;
    }
}

.fault-box{
    min-width: 883px;
    margin-top : 10px;
    background: #ffffff;
    border-radius: 2px;
    box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.10); 
    .fault-top{
        border-bottom: 1px solid #e5e5e5;
        background: #fafafa;
        border-radius: 2px;
        padding: 0 18px;
        .title{
            font-size: 16px;
            font-weight: 700;
            line-height: 70px;
            float: left;
            margin-left:26px ;
        }
        .search{
            height: 48px;
            line-height: 70px;
        }
        .datepicker{
            height: 48px;
            line-height: 70px;
            /deep/ .el-date-editor{
                width: 100%;
            }
            margin-right:10px ;
        }
        .selectMachine{
            line-height: 70px;
            height: 48px;
            margin-right:10px ;
            .el-select{
                width: 100%;
            }
        }
    }
    .fault-content{
        padding: 20px;
        .fault-content-top{
            height: 58px;
            border-bottom: 1px solid #e5e5e5;
            position:relative;
            .tab{
                position: absolute;
                float: left;
                bottom: 1px;
                span{
                    width: 200px;
                    height: 38px;
                    background: #f2f2f2;
                    border-radius: 2px 2px 0px 0px;
                    font-size: 14px;
                    cursor: pointer;
                    display: inline-block;
                    line-height: 38px;
                    margin: 0 1.5px;
                    &.active {
                        color: #fff;
                        background: #0079C1;
                    }
                    &:hover {
                        color: #fff;
                        background: #0079C1;
                    }
                }
            }
            .button{
                float: right;
                .el-button{
                    height: 38px;
                }
            }
        }
        .fault-list{
            margin-top:20px ;
            .fault-list-row{
                margin-bottom: 10px;
                .fault-title{
                    min-height: 56px;
                    background: #fafafa;
                    border: 1px solid #dddddd;
                    border-radius: 2px 2px 0px 0px;
                    &:hover {
                        box-shadow: 0 0 15px #409EFF;
                    }
                    .fault-active{
                        margin-left: 23px;
                        margin-top: 13px;
                        width: 93px;
                        height: 30px;
                        background:rgba(255,0,0,0.1);
                        border-radius: 15px;
                        color: #FF0000;
                        font-size: 14px;
                        line-height: 30px;
                        float: left;
                    }
                    .fault-resolved{
                        margin-left: 23px;
                        margin-top: 13px;
                        width: 93px;
                        height: 30px;
                        background: rgba(0,177,148,0.1);
                        border-radius: 15px;
                        color: #00B194;
                        font-size: 14px;
                        line-height: 30px;
                        float: left;
                    }
                    .fault-code{
                        font-size: 16px;
                        font-weight: 700;
                        color: #333333;
                        float: left;
                        margin-left: 21px;
                    }
                    .fault-name{
                        font-size: 16px;
                        margin-left: 43px;
                        float: left;
                    }
                    .fault-date{
                        font-size: 16px;
                        color: #999999;
                        float: right;
                        margin-right: 23px;
                    }
                    .fault-eidt{
                        padding-top: 3px;
                        height: 19px;
                        width: 19px;
                        
                        float: right;
                        margin-right: 36px;
                        img{
                            cursor: pointer;
                        }
                    }
                    line-height: 56px;
                }
                .fault-dec{
                    border: 1px solid #dddddd;
                    border-top: 0;
                    border-radius: 0px 0px 2px 2px;
                    .fault-info{
                        padding: 25px 32px 25px 25px;
                        font-size: 14px;
                        border-right: 1px solid #dddddd;
                        .el-row{
                            margin-bottom: 2px;
                        }
                        .info-title{
                            width: 100%;
                            height: 30px;
                            line-height: 30px;
                            background: #e5f1f9;
                            border-radius: 12px 0px 0px 12px;
                            color: #0079c1;
                            float: right;
                            margin-right:3px ;
                        }
                        .info-result{
                            padding-left:  9px;
                            width: 100%;
                            line-height: 30px;
                            height: 30px;
                            background: #fafafa;
                            float: left;
                            text-align: left;
                        }
                    }
                    .row-right{
                        padding: 25px 32px 25px 25px;
                        .row-right-title{
                            font-size: 14px;
                            font-weight: 700;
                            text-align: left;
                        }
                        .row-right-remark{
                            word-wrap:break-word;
                            font-size: 14px;
                            text-align: left;
                        }
                    }
                }
            }
            .more{
                height: 60px;
                background: #fafafa;
                border: 1px solid #dddddd;
                border-radius: 2px;
                text-align: center;
                font-size: 16px;
                line-height: 60px;
                cursor: pointer;

                &:hover {
                    box-shadow: 0 0 15px #409EFF;
                }
            }
            
        }
    }
    
}
