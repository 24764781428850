html,
body,
#app {
  height: 100%;
  padding: 0px;
  margin: 0px;
}
.clear {
  clear: both;
}
div::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
div::-webkit-scrollbar-track {
  background: rgb(239, 239, 239);
  border-radius: 2px;
}
div::-webkit-scrollbar-thumb {
  background: #bfbfbf;
  border-radius: 10px;
}
div::-webkit-scrollbar-thumb:hover {
  background: #888;
}
div::-webkit-scrollbar-corner {
  background: #a3aaa3;
}

.none-data-box {
  text-align: center;
  font-size: 18px;
  height: 50px;
  line-height: 50px;
}


.el-table .even-row {
  background: #FAFAFA;
}

.popover-content-box {
  background-color: #fcf7e7!important;
  border: 1px solid #ffc529!important;
  border-radius: 2px!important;
  font-size: 16px!important;
  font-weight: 400!important;
  text-align: justifyLeft!important;
}

.popover-content-box[x-placement^="right"] .popper__arrow {
  border-right-color: #ffc529!important;
}
.popover-type-box[x-placement^="bottom"] .popper__arrow{
  border-bottom-color: #3399FF!important;
}

.popover-type-box {
  border: 1px solid #3399FF!important;
  border-radius: 2px!important;
  font-size: 16px!important;
  font-weight: 400!important;
  text-align: justifyLeft!important;
}
.el-table-filter{
  max-height: 300px !important;
  overflow-y: auto !important;
}
