







































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.machine-control-box {
    .mapboxgl-popup-content {
        background: rgba(0,0,0,0);
        padding: 0;
    }
    .mark-content-box {
        color: #fff;
        text-align: left;
        padding: 20px;
        font-size: 16px;
        opacity: 0.9;
        border-radius: 2px;
        &>div {
            line-height: 25px;
        }

        .mark-content-title {
            font-size: 18px;
        }
    }
}
