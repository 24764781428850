

























































































































.pop-box {
    margin-top: 16vh;
    /deep/.el-dialog__footer {
        text-align: center;
    }

    /deep/.el-select {
        width: 100%;
    }
}
