





















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.fault-box{
    min-width: 1042px;
    margin-top : 10px;
    background: #ffffff;
    border-radius: 2px;
    box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.10); 
    position: relative;
    .arrow{
        background:url("../../assets/image/download1.png") no-repeat 0px 0px;
        cursor: pointer;
        width: 47px;
        height: 100px;
        position:fixed;
        top: 50%;
        z-index: 10;
        transform: translateY(-50%);
        &:hover {
            background:url("../../assets/image/download2.png") no-repeat 0px 0px;
        }
    }
    .right{
        right: 1px;
    }
    .marginBottom {
        margin-bottom: 20px;
    }
    .tab{
        float: left;
        text-align: center;
        span{
            width: 244px;
            height: 38px;
            background: #f2f2f2;
            border-radius: 2px 2px 0px 0px;
            font-size: 14px;
            cursor: pointer;
            display: inline-block;
            line-height: 38px;
            margin: 0 1.5px;
            &.active {
                color: #fff;
                background: #0079C1;
            }
            &:hover {
                color: #fff;
                background: #0079C1;
            }
        }
    }
    .fault-top{
        border-bottom: 1px solid #e5e5e5;
        background: #fafafa;
        border-radius: 2px;
        padding: 0 18px;
        .title{
            font-size: 16px;
            font-weight: 700;
            line-height: 70px;
            float: left;
            margin-left:26px ;
        }
        .datepicker{
            height: 48px;
            line-height: 70px;
            /deep/ .el-date-editor{
                width: 100%;
            }
        }
        .selectMachine{
            height: 48px;
            margin-right:10px ;
            line-height: 70px;
            .el-select{
                width: 100%;
            }
        }
    }
    .fault-content{
        padding: 20px 20px 0 20px;
        .infofrmation-fault-box {
            height: 420px;
            .chart{
                padding: 38px;
                .infofrmation-fault-pie-box {
                    width: 100%;
                    height: 340px;
                    float: right;
                }
                .infofrmation-fault-bar-box {
                    width: 100%;
                    height: 340px;
                    float: left;
                }
            }
            
        }
        .fault-list{
            margin-top:20px ;
            .fault-list-row{
                margin-bottom: 10px;
                .fault-title{
                    cursor: pointer;
                    display: flex;
                    height: 86px;
                    justify-content:space-between;
                    background: #fafafa;
                    border: 1px solid #dddddd;
                    border-radius: 2px 2px 0px 0px;
                    &:hover {
                        box-shadow: 0 0 15px #409EFF;
                    }
                    .fault-vehicle{
                        height: 12px;
                        font-size: 16px;
                        font-weight: 700;
                        color: #333333;
                        margin-left: 35px;
                    }
                    .fault-button{
                        margin: 24px 31px 0 0;
                        width: 164px;
                        height: 38px;
                        background: #00b194;
                        border-radius: 2px;
                        font-size: 14px;
                        font-weight: 400;
                        color: #ffffff;
                    }
                    line-height: 86px;
                }
                .fault-table{
                    padding: 24px 34px 0 34px;
                    /deep/ .el-table__header tr th {
                        background: #FAFAFA;
                    }
                    /deep/ .el-table td.el-table__cell, .el-table th.el-table__cell.is-leaf {
                        border-bottom: 1px solid #ebeef5;
                    }
                }
            }
        }
    }
}
