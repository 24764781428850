




























































.main {
  cursor: pointer;
}
.el-dropdown-link {
  min-width: 150px;
  font-size: 14px;
  text-align: left;
  color: #333333;
}
