/*车辆信息样式 */
.machineDevicesConfig-content {
  padding: 0 12% 1% 12%;
  margin-top: 3%;
  /*隐藏数字框右边按钮 */
  /*NodeId样式 */
  /*底部按钮样式 */
  /*弹窗底部按钮样式 */
  /*弹窗标题样式 */
}
.machineDevicesConfig-content input::-webkit-outer-spin-button,
.machineDevicesConfig-content input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
.machineDevicesConfig-content input[type="number"] {
  -moz-appearance: textfield;
}
.machineDevicesConfig-content .unit {
  margin: 0px 0px 0px 10px;
  color: #333333;
}
.machineDevicesConfig-content .el-col {
  text-align: left;
}
.machineDevicesConfig-content .el-col .el-input--prefix .el-input__inner {
  padding-left: 34px;
}
.machineDevicesConfig-content .el-upload--picture-card {
  width: 100%;
  border: 1px solid #ccc;
  background-color: #fff;
  padding: 27% 0;
  line-height: inherit;
  height: auto;
}
.machineDevicesConfig-content .el-upload--picture-card i {
  font-weight: 1000;
  color: #555555;
}
.machineDevicesConfig-content .el-upload--picture-card .haveFile {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 0 10%;
}
.machineDevicesConfig-content .device {
  font-weight: 700;
}
.machineDevicesConfig-content .type {
  margin: 0 30px 0 10px;
}
.machineDevicesConfig-content .count {
  color: #0066CC;
  font-weight: 700;
}
.machineDevicesConfig-content .addButton {
  text-align: right !important;
}
.machineDevicesConfig-content .addButton .el-button {
  width: 110px;
  height: 38px;
  border-radius: 4px;
}
.machineDevicesConfig-content .el-table {
  margin-top: 20px;
  border: 1px solid #f0f0f0;
  width: 100%;
}
.machineDevicesConfig-content .el-table .filter i {
  margin-left: 8px;
}
.machineDevicesConfig-content .el-table .operation {
  display: inline-block;
  font-size: 20px;
}
.machineDevicesConfig-content .el-table .operation .el-icon-delete {
  margin-left: 30px;
  color: #FF4D4F;
}
.machineDevicesConfig-content .el-table .operation .el-icon-edit {
  color: #047BC2;
}
.machineDevicesConfig-content .el-table th {
  background-color: #fafafa;
}
.machineDevicesConfig-content .el-table td {
  border-bottom: 1px solid #f0f0f0;
}
.machineDevicesConfig-content .footer {
  text-align: center;
  margin: 30px 0;
}
.machineDevicesConfig-content .footer .el-button {
  width: 110px;
  height: 38px;
}
.machineDevicesConfig-content .footer .el-button--default:hover {
  background: transparent !important;
}
.machineDevicesConfig-content .footer .el-button--primary {
  margin-left: 20px;
}
.machineDevicesConfig-content .border {
  padding-top: 20px;
  border-top: 1px solid #eaeef0;
  margin: 0 -2%;
}
.machineDevicesConfig-content .dialogFooter {
  text-align: left;
}
.machineDevicesConfig-content .dialogFooter .el-button {
  width: 110px;
  height: 38px;
}
.machineDevicesConfig-content .dialogFooter .el-button--default:hover {
  background: transparent !important;
}
.machineDevicesConfig-content .dialogFooter .el-button--primary {
  margin-left: 20px;
}
.machineDevicesConfig-content .el-dialog__header {
  padding: 20px !important;
  padding-bottom: 10px !important;
  height: auto !important;
  text-align: left;
  background: #f2f2f2;
  border-bottom: 1px solid #eaeef0;
}
.warningTip {
  text-align: center;
  width: 504px !important;
}
.warningTip .el-message-box__header {
  padding: 0;
}
.warningTip .el-message-box__content {
  padding: 0;
}
.warningTip .text {
  margin-top: 27px;
  margin-bottom: 30px;
  font-size: 16px;
}
.warningTip .el-button {
  width: 110px;
  height: 38px;
  background: #fff;
  font-size: 14px;
}
.warningTip .el-button--default:hover {
  background: transparent;
}
.warningTip .el-button--primary {
  margin-left: 20px !important;
  background: #0079c1;
}
.warningTip .el-button--primary:hover {
  background: #0369a6;
}
.typePopper {
  padding: 0px !important;
}
.typePopper .typeItem {
  padding: 0px 30px 10px 30px;
}
.typePopper .firstItem {
  border-bottom: 1px solid #f2f2f2;
  padding: 10px 30px;
  margin-bottom: 10px;
}
.typePopper .el-checkbox__inner {
  border-radius: 2px;
}
