.switch-menu {
  padding-top: 0;
  padding-left: 19px;
  text-align: left;
  font-size: 19px;
  cursor: pointer;
}
.switch-button i:hover {
  color: #0079c1;
}
