/*车辆信息样式 */
.machineFaultConfig-content {
  padding: 0 12% 1% 12%;
  margin-top: 3%;
  /*底部按钮样式 */
  /*弹窗底部按钮样式 */
  /*弹窗标题样式 */
}
.machineFaultConfig-content .el-col {
  text-align: left;
}
.machineFaultConfig-content .el-tabs--card > .el-tabs__header {
  border-bottom: 1px solid #eee;
}
.machineFaultConfig-content .el-tabs--card > .el-tabs__header .el-tabs__nav {
  border: 0px solid;
}
.machineFaultConfig-content .el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
  background: #047bc2;
  color: #fff;
}
.machineFaultConfig-content .el-tabs--card > .el-tabs__header .el-tabs__item {
  border-left: 0px solid;
  margin-right: 10px;
  background: #f5f6f7;
  color: #999;
  border-radius: 2px 2px 0px 0px;
}
.machineFaultConfig-content .device {
  font-weight: 700;
  line-height: 40px;
}
.machineFaultConfig-content .device .count {
  color: #0066CC;
  font-weight: 700;
}
.machineFaultConfig-content .unit {
  margin: 12px 10px;
  color: #666;
  font-size: 15px;
}
.machineFaultConfig-content .el-table {
  margin-top: 20px;
  border: 1px solid #f0f0f0;
  width: 100%;
}
.machineFaultConfig-content .el-table .operation {
  display: inline-block;
  font-size: 20px;
}
.machineFaultConfig-content .el-table .operation .el-icon-edit {
  color: #047BC2;
}
.machineFaultConfig-content .el-table .operation .el-icon-document {
  color: #00b194;
}
.machineFaultConfig-content .el-table .el-table__expand-icon > .el-icon {
  display: none;
}
.machineFaultConfig-content .el-table .el-table__expanded-cell {
  padding: 20px 25px;
  background: #fafafa;
}
.machineFaultConfig-content .el-table th {
  background-color: #fafafa;
  font-weight: 400;
  color: #333;
}
.machineFaultConfig-content .el-table td {
  border-bottom: 1px solid #f0f0f0;
}
.machineFaultConfig-content .el-textarea__inner {
  padding: 10px 15px !important;
}
.machineFaultConfig-content #qrCode {
  display: none;
}
.machineFaultConfig-content .footer {
  text-align: center;
  margin: 30px 0;
}
.machineFaultConfig-content .footer .el-button {
  width: 110px;
  height: 38px;
}
.machineFaultConfig-content .footer .el-button--default:hover {
  background: transparent !important;
}
.machineFaultConfig-content .footer .el-button--primary {
  margin-left: 20px;
}
.machineFaultConfig-content .border {
  padding-top: 20px;
  border-top: 1px solid #eaeef0;
  margin: 0 -2%;
}
.machineFaultConfig-content .el-input.is-disabled .el-input__inner {
  border-color: #ccc;
}
.machineFaultConfig-content .dialogFooter {
  text-align: left;
}
.machineFaultConfig-content .dialogFooter .el-button {
  width: 110px;
  height: 38px;
}
.machineFaultConfig-content .dialogFooter .el-button--default:hover {
  background: transparent !important;
}
.machineFaultConfig-content .dialogFooter .el-button--primary {
  margin-left: 20px;
}
.machineFaultConfig-content .el-dialog__header {
  padding: 20px !important;
  height: auto !important;
  text-align: left;
  background: #f2f2f2;
  border-bottom: 1px solid #eaeef0;
}
