.qrcode {
  font-size: 14px;
  font-weight: 400;
  color: #0079c1;
  cursor: pointer;
}
.RDM-box {
  margin-top: 10px;
  background: #ffffff;
  border-radius: 2px;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1);
}
.RDM-box .RDM-top {
  border-bottom: 1px solid #e5e5e5;
  background: #fafafa;
  border-radius: 2px;
  padding: 0 18px;
  height: 79px;
}
.RDM-box .RDM-top .title {
  font-size: 16px;
  font-weight: 700;
  line-height: 79px;
  float: left;
  margin-left: 26px ;
}
.RDM-box .RDM-top .search {
  height: 48px;
  line-height: 79px;
}
.RDM-box .RDM-content {
  padding: 20px;
}
.RDM-box .RDM-content .RDM-content-top {
  float: left;
}
.RDM-box .RDM-content .RDM-table {
  margin-top: 21px;
}
.RDM-box .RDM-content .RDM-table .table-box {
  border: 1px solid #F2F2F2;
}
.RDM-box .RDM-content .RDM-table .table-box /deep/ .el-table__header tr th {
  background: #FAFAFA;
}
.RDM-box .RDM-content .RDM-table .table-box .table-operation {
  display: flex;
  justify-content: space-around;
  color: #0079C1;
}
.RDM-box .RDM-content .RDM-table .table-box .table-operation i {
  cursor: pointer;
}
.RDM-box .RDM-content .RDM-table .table-box .table-operation i:hover {
  opacity: 0.8;
}
.RDM-box .RDM-content .RDM-table .table-box .table-operation i:last-child {
  color: #FF4D4F;
}
.RDM-box .RDM-content .RDM-table .pagination {
  margin-top: 20px;
  text-align: right;
  padding-bottom: 40px;
}
.RDM-box .RDM-content .RDM-table .pagination .paginationCount {
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  color: #666666;
}
.RDM-box .RDM-content .RDM-table .pagination .paginationDiv {
  display: inline-block;
}
.RDM-box .RDM-content .RDM-table .rdmon {
  cursor: pointer;
  width: 19px;
  height: 19px;
  margin: 0;
  background: url('../../assets/image/rdmon.png');
}
.RDM-box .RDM-content .RDM-table .point {
  cursor: pointer;
}
.RDM-box .RDM-content .RDM-table .gray {
  font-size: 21px;
  color: #999999;
}
.RDM-box .RDM-content .RDM-table .green {
  font-size: 21px;
  color: #00b194;
}
.RDM-box .RDM-content .RDM-table .red {
  font-size: 21px;
  color: #fe686a;
}
.network-icon-off {
  display: inline-block;
  width: 21px;
  height: 18px;
  margin: 0 auto;
  background: url('../../assets/image/wifi_off.png');
}
.sw-config-icon-yes {
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: 13px;
  background-image: url("../../assets/image/config_1.png");
}
.sw-config-icon-no {
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: 13px;
  background-image: url("../../assets/image/config_2.png");
}
.process-backgroud {
  background-color: #F2F2F2;
  border-radius: 6px;
}
