
















































































































































.NavMenu {
  display: flex;
  height: 100%;
  flex-direction: column;
  background: #04223a;
  box-shadow: 0px 0px 6px 0px undefined;
}
.el-menu-vertical-demo {
  flex-grow: 1;
  background: #04223a;
}
.el-menu-vertical-demo .el-menu-item {
  padding-left: 50px !important;
}
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 237px;
  text-align: left;
}
.switch-menu {
  flex-grow: 0;
  height: 100px;
  line-height: 100px;
}
.switch-button {
  font-size: 28px;
  cursor: pointer;
  width: 28px;
}

.company-logo {
  height: 70px;
  background: #04223a;
  border-bottom: #00b194 1px solid;
}
.company-logo-img1 {
  height: 45px; 
  width: 160px; 
  margin: 15px 0px 0px 25px;
  float: left;
}
.company-logo-title {
  font-size: 21px;
  font-family: Arial, Arial-Regular;
  margin-top: 25px;
  text-align: center;
  float: left;
  margin-left: 13px;
  color: #ffffff;
}
.company-logo-title-simple {
  height: 70px;
  background: #04223a;
  border-bottom: #00b194 1px solid;
}
.company-logo-img2 {
  height: 31px;
  width: 31px;
  margin-left: 27px;
  margin-right: 43px;
  margin-top: 20px;
}

.menu {
  margin-top: 24px;
  // margin-bottom: 100px;
  /*overflow-y: auto;*/
}
.el-submenu .el-menu-item {
  line-height: initial;
  min-height: 50px;
  padding-top: 15px;
}
.el-menu-item .submenu {
  white-space: pre-line;
}

.activeMenu {
  border-left: solid 2px #00b194;
  background: linear-gradient(to right, #034b54 0%, #04223a 100%);

  .menuName {
    color: #0079c1;
  }
}

/deep/ .el-submenu__title:hover {
  background: linear-gradient(to right, #034b54 0%, #04223a 100%);
}
/deep/ .el-menu-item:focus,
.el-menu-item:hover {
  background: linear-gradient(to right, #034b54 0%, #04223a 100%);
}

/deep/ .el-menu {
  border-right: 0;
}
/deep/ .el-submenu__title {
  padding-left: 33px !important;
  padding-right: 50px;
}
.menu-bottom-copyright {
  position: absolute;
  bottom: 40px;
  left: 10px;
  .text {
    letter-spacing: -0.5px;
    font-size: 9px;
    font-family: Arial, Arial-Regular;
    font-weight: 400;
    text-align: center;
    color: #006699;
  }
}
.menuItem {
  font-size: 14px;
  font-family: Arial, Arial-Regular;
  font-weight: 400;
  text-align: left;
  color: #ffffff;
  margin-left: 13px;
}
.submenu {
  color: #ffffff;
}
.el-menu-item {
  background-color: #04223a;
}
