


































































































































































































































































































































































































































































































































































































































































































































































































/*车辆信息样式 */
.machineDevicesConfig-content{
  padding: 0 12% 1% 12%;
  margin-top: 3%;
  /*隐藏数字框右边按钮 */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }
  /*NodeId样式 */
  .unit {
    margin: 0px 0px 0px 10px;
    color: #333333;
  }
  .el-col{
    text-align: left;
    .el-input--prefix .el-input__inner {
      padding-left: 34px;
    }
  }
  .el-upload--picture-card{
    width: 100%;
    border: 1px solid #ccc;
    background-color: #fff;
    padding: 27% 0;
    line-height: inherit;
    height: auto;
    i{
      font-weight: 1000;
      color: #555555;
    }
    .haveFile{
      white-space: nowrap;
      text-overflow:ellipsis;
      overflow: hidden;
      padding: 0 10%;
    }
  }
  .device{
    font-weight: 700;
  }
  .type{
    margin: 0 30px 0 10px;
  }
  .count{
    color:#0066CC;
    font-weight: 700;
  }
  .addButton{
    text-align: right !important;
    .el-button{
      width: 110px;
      height: 38px;
      border-radius: 4px;
    }
  }
  .el-table{
    margin-top: 20px;
    border: 1px solid #f0f0f0;
    width: 100%;
    .filter i{
      margin-left: 8px;
    }
    .operation{
      display: inline-block;
      font-size: 20px;
      .el-icon-delete{
        margin-left: 30px;
        color: #FF4D4F;
      }
      .el-icon-edit{
        color: #047BC2;
      }
    }
    th{
      background-color: #fafafa;
    }
    td{
      border-bottom: 1px solid #f0f0f0;
    }
  }
  /*底部按钮样式 */
  .footer{
    text-align: center;
    margin: 30px 0;
    .el-button{
      width: 110px;
      height: 38px;
    }
    .el-button--default:hover{
      background: transparent !important;
    }
    .el-button--primary{
      margin-left: 20px;
    }
  }
  .border{
    padding-top: 20px;
    border-top: 1px solid #eaeef0;
    margin: 0 -2%;
  }
  /*弹窗底部按钮样式 */
  .dialogFooter{
    text-align: left;
    .el-button{
      width: 110px;
      height: 38px;
    }
    .el-button--default:hover{
      background: transparent !important;
    }
    .el-button--primary{
      margin-left: 20px;
    }
  }
  /*弹窗标题样式 */
  .el-dialog__header {
    padding: 20px !important;
    padding-bottom: 10px !important;
    height: auto !important;
    text-align: left;
    background: #f2f2f2;
    border-bottom: 1px solid #eaeef0;
  }
}
.warningTip{
  text-align: center;
  width: 504px !important;
  .el-message-box__header{
    padding: 0;
  }
  .el-message-box__content{
    padding:0;
  }
  .text{
    margin-top: 27px;
    margin-bottom: 30px;
    font-size: 16px;
  }
  .el-button {
    width: 110px;
    height: 38px;
    background: #fff;
    font-size: 14px;
  }
  .el-button--default:hover {
    background: transparent;
  }
  .el-button--primary {
    margin-left: 20px !important;
    background: #0079c1;
  }
  .el-button--primary:hover {
    background: #0369a6;
  }
}
.typePopper{
  padding:0px !important;
  .typeItem{
    padding: 0px 30px 10px 30px;
  }
  .firstItem{
    border-bottom: 1px solid #f2f2f2;
    padding: 10px 30px;
    margin-bottom: 10px;
  }
  .el-checkbox__inner{
    border-radius: 2px;
  }
}

