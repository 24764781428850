.assign-box {
  margin-top: 10px;
  background: #ffffff;
  border-radius: 2px;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1);
}
.assign-box .assign-top {
  border-bottom: 1px solid #e5e5e5;
  background: #fafafa;
  border-radius: 2px;
  padding: 0 18px;
}
.assign-box .assign-top .header-crumbs {
  margin-left: 26px;
  font-size: 16px;
  font-weight: bold;
  float: left;
  height: 79px;
  line-height: 79px;
}
.assign-box .assign-top .header-crumbs .el-breadcrumb {
  vertical-align: middle;
  display: inline-block;
}
.assign-box .assign-content {
  padding: 0 40px 60px;
}
.assign-box .assign-content .assign-content-top {
  height: 55px;
}
.assign-box .assign-content .assign-content-top .title {
  font-size: 14px;
  float: left;
  line-height: 55px;
  color: #333333;
}
.assign-box .assign-content .assign-content-top .search {
  float: right;
  line-height: 55px;
  width: 100%;
}
.assign-box .assign-content .assign-content-top .company-box {
  text-align: left;
  max-height: 700px;
  border: 1px solid #cccccc;
  overflow-y: auto;
}
.assign-box .assign-content .assign-content-top .company-box /deep/ .el-radio {
  display: block;
  margin: 10px auto;
}
.assign-box .assign-content .assign-content-top .company-box .content {
  padding: 20px;
}
.assign-box .assign-content .assign-content-top .company-box .foot {
  height: 78px;
  border-top: 1px solid #cccccc;
  display: flex;
  justify-content: center;
  align-items: center;
}
.assign-box .assign-content .assign-content-top .company-box .foot button {
  width: 110px;
  height: 38px;
}
.assign-box .assign-content .assign-content-top .company-box .foot .el-button + .el-button {
  margin-left: 20px;
}
