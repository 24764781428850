.company-box {
  width: 100%;
  height: 100%;
  position: relative;
}
.company-box p {
  margin: 0;
}
.company-box em {
  font-style: inherit;
}
.company-box .overall-box {
  width: 100%;
  height: 80px;
  line-height: 80px;
}
.company-box .overall-box .num-box {
  color: #333;
  font-size: 16px;
  line-height: 80px;
  text-align: left;
  float: left;
  margin-left: 45px;
}
.company-box .overall-box .num-box em {
  font-weight: bold;
  padding-right: 14px;
}
.company-box .overall-box .search-box {
  width: 40%;
  height: 38px;
  float: right;
}
.company-box .list-box .list {
  height: 136px;
  min-width: 756px;
  color: #666;
  background: #fff;
  text-align: left;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 10px;
  transition: all 0.4s;
  cursor: pointer;
  border-radius: 2px;
}
.company-box .list-box .list:hover {
  box-shadow: 0 0 15px #409EFF;
}
.company-box .list-box .list img {
  width: 83px;
  height: 83px;
  padding-left: 32px;
  padding-right: 45px;
  padding-top: 26px;
  display: block;
  float: left;
}
.company-box .list-box .list em {
  color: #333;
  font-weight: bold;
  font-size: 16px;
  padding-left: 13px;
}
.company-box .list-box .list .desc {
  display: block;
  /*float: left;*/
}
.company-box .list-box .list .desc .name {
  padding-top: 40px;
  padding-bottom: 30px;
}
.company-box .list-box .list .desc .info p {
  display: block;
  float: left;
  padding-right: 8%;
}
.company-box .list-box .list .desc .info p .color1 {
  color: #0079C1;
}
.company-box .list-box .list .desc .info p .color2 {
  color: #F4506B;
}
.company-box .list-box .list .desc .info p .color3 {
  color: #00B194;
}
.company-box .list-box .list .desc .info p .color4 {
  color: #909399;
}
.company-box .list-box .more {
  height: 60px;
  line-height: 60px;
  text-align: center;
  background: #fff;
  font-size: 16px;
  color: #333;
  transition: all 0.4s;
  cursor: pointer;
}
.company-box .list-box .more:hover {
  box-shadow: 0 0 15px #409EFF;
}
